import { AuthMethodTypeEnum } from "@/api/common-types";
import ApiManagementCreateApp from "@/components/api-management/ApiManagementCreateApp.component";
import ApiManagementSectionHeader from "@/components/api-management/ApiManagementSectionHeader.component";
import Plate from "@/components/shared/Plate.component";
import Table from "@/components/shared/table/Table.component";
import TableActions from "@/components/shared/table/TableActions.component";
import tableActionsStyle from "@/components/shared/table/TableActions.component.module.scss";
import { SelectedDataExtractor } from "@/components/shared/table/selected-data-extractor";
import { TableEditingState } from "@/components/shared/table/table-editing-state";
import { TableSelectionState } from "@/components/shared/table/table-selection-state";
import {
  ICellChangePayload,
  ITableField,
} from "@/components/shared/table/types";
import { getIconForFieldType } from "@/components/shared/table/utils";
import {
  ICustomerApp,
  ICustomerAppDeleteInput,
  IQueryListCustomerAppsByApiArgs,
  SortOrderType,
} from "@/generated/types";
import { BAlert, BContainer } from "@/lib/typed-bootstrap";
import UserPermissions from "@/mixins/UserPermissions.mixin";
import {
  BaseFieldTypeEnum,
  ComplexFieldTypeEnum,
  DateFieldTypeEnum,
  IApiState,
} from "@/models";
import {
  deleteCustomerApp,
  updateCustomerApp,
  watchCustomerAppsByApiId,
} from "@/models/customer-apps";
import { getErrorMessage } from "@/utils";
import { ListDataManager } from "@/utils/list-data-manager";
import _pick from "lodash/pick";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import styles from "./ProjectApps.view.module.scss";

const getInitialVariables = (
  apiId: string
): IQueryListCustomerAppsByApiArgs => ({
  limit: 10,
  nextToken: null,
  filter: undefined,
  sortOrder: SortOrderType.DESC,
  apiId,
});

@Component
export default class ProjectApps extends UserPermissions {
  @Prop({ required: true }) private value: IApiState;

  private isCreationPlateOpen = false;
  private listDataManager = Vue.observable(
    new ListDataManager<
      { items: ICustomerApp[]; nextToken?: string | null },
      IQueryListCustomerAppsByApiArgs
    >(watchCustomerAppsByApiId, {
      perPage: 10,
      pageSizes: [10, 50],
      currentPage: 1,
    })
  );
  private hasEditColumn = true;

  $refs!: {
    plate?: Plate;
    tableActions?: TableActions;
  };

  @Watch("value.id", { immediate: true })
  onApiIdChange() {
    if (this.value.id) {
      this.listDataManager.subscribeToList(getInitialVariables(this.value.id));
    }
  }

  private get items() {
    return this.listDataManager.data.map((item) => ({
      ...item,
      appUrl: `${process.env.VUE_APP_CUSTOMER_APPS_URL}/${item.id}`,
    }));
  }

  private get tableSelectionState() {
    return new TableSelectionState(false, false);
  }

  private get fields(): ITableField[] {
    return [
      {
        label: "Updated At",
        key: "updatedAt",
        type: DateFieldTypeEnum.DateTime,
        readOnly: true,
      },
      { label: "Name", key: "name", type: BaseFieldTypeEnum.String },
      {
        label: "App Url",
        key: "appUrl",
        type: ComplexFieldTypeEnum.Url,
        readOnly: true,
      },
    ].map((field) => ({
      ...field,
      iconSrc: getIconForFieldType(field.type).light,
      inverseIconSrc: getIconForFieldType(field.type).dark,
    }));
  }

  private get tableEditingState() {
    return Vue.observable(new TableEditingState(this.fields));
  }

  private closeAddEntryPlate() {
    this.isCreationPlateOpen = false;
  }

  private openAddEntryPlate() {
    this.isCreationPlateOpen = true;
  }

  private async onDeleteEntries() {
    try {
      if (this.hasAdminPermissions) {
        const selectedEntries: ICustomerAppDeleteInput[] = this.listDataManager
          .entriesFromSelectionState(this.tableSelectionState)
          .map((entry) => _pick(entry, ["id"]) as ICustomerAppDeleteInput);

        if (
          window.confirm(
            this.$t("api_details_data_delete_entries_confirm", {
              numOfEntries:
                selectedEntries.length > 1
                  ? this.$t("global_many_entries", {
                      numOfEntries: selectedEntries.length,
                    })
                  : this.$t("global_single_entry"),
              typeName: "Customer Apps",
            }).toString()
          )
        ) {
          await Promise.all(
            selectedEntries.map((entry) => deleteCustomerApp({ id: entry.id }))
          );
        }
      } else {
        throw new Error(
          this.$t("error_api_management_requires_role").toString()
        );
      }
    } catch (err) {
      this.listDataManager.errorMessage = getErrorMessage(err);
    }
  }

  private selectedDataExtractor = new SelectedDataExtractor(
    [],
    [],
    false,
    this.hasEditColumn
  );

  private get hasCognitoAuth() {
    return (
      this.value.defaultAuthMode === AuthMethodTypeEnum.AWS_COGNITO_USER_POOLS
    );
  }

  private get addEntryButtonRef() {
    return this.$refs.tableActions?.addEntryButtonRef;
  }

  private async handleCellChange(payload: ICellChangePayload) {
    await updateCustomerApp({
      id: `${payload.item?.id ?? ""}`,
      [payload.field]: payload.value,
    });
    this.tableEditingState.isEditing = false;
    this.tableEditingState.shouldReplaceContent = false;
    this.tableSelectionState.deselectAll();
  }

  private handleEditTableEntry(index: number) {
    const app = this.items[index];
    this.$router.push({
      name: "project-app-editor",
      params: {
        appId: app.id,
      },
    });
  }

  render() {
    return (
      <BContainer class="api__view-setup" fluid>
        <ApiManagementSectionHeader
          title={this.$t("api_details_section_title_apps").toString()}
          description={this.$t(
            "api_details_section_description_apps"
          ).toString()}
        />
        <BAlert
          show={this.listDataManager.showErrorMessage}
          variant="danger"
          data-testid="apiSettingsFormError"
        >
          {this.listDataManager.errorMessage}
        </BAlert>
        <TableActions
          class={tableActionsStyle.roundedBorderTop}
          canAddItems={this.hasCognitoAuth}
          onAddEntry={this.openAddEntryPlate}
          withDelete
          onDeleteEntries={this.onDeleteEntries}
          canDeleteItems={this.tableSelectionState.hasOnlyFullRowsSelected}
          ref="tableActions"
        />
        {this.isCreationPlateOpen && this.addEntryButtonRef && (
          <ApiManagementCreateApp
            anchor={this.addEntryButtonRef}
            onClose={this.closeAddEntryPlate}
            apiId={this.value.id}
          />
        )}
        <div class={styles.progressPlaceholder} />
        <Table
          ref="table"
          items={this.items}
          hasMoreItems={!!this.listDataManager.listVariables?.nextToken}
          withEditColumn={this.hasEditColumn}
          withSelectionColumn
          fields={this.fields}
          selectedDataExtractor={this.selectedDataExtractor}
          tableSelectionState={this.tableSelectionState}
          tableEditingState={this.tableEditingState}
          onPaginationChange={this.listDataManager.onPaginationChange}
          paginationState={this.listDataManager.paginationState}
          onEditTableEntry={this.handleEditTableEntry}
          onCellChange={this.handleCellChange}
        >
          {this.$t("form_table_empty_text")}
        </Table>
      </BContainer>
    );
  }
}
