import { BillingIntervalEnum, CurrencyTypeEnum } from "@/api/common-types";
import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import {
  IFreeTiers,
  IPriceState,
  IProductState,
} from "@/models/product-bundle";
import { ILocalizedBenefits } from "@/models/benefit";
import { Button } from "../shared/Button.component";
import benefitCheckmarkIconBlack from "@/assets/img/icon-checkmark-benefit-black.svg";
import iconWidgetSelectedBlack from "@/assets/img/icon-widget-selected-dark.svg";
import * as tsx from "vue-tsx-support";

@Component({
  components: {
    Button,
  },
})
export default class BundleWidget extends Vue {
  _tsx!: tsx.DeclareProps<{
    value: BundleWidget["value"];
    product: BundleWidget["product"];
    billingInterval: BundleWidget["billingInterval"];
    freeTiers?: BundleWidget["freeTiers"];
  }> &
    tsx.DeclareOnEvents<{
      onChooseBundle: void;
    }>;

  @Prop({ required: true }) value: boolean;
  @Prop({ required: true }) product: IProductState;
  @Prop() billingInterval: BillingIntervalEnum;
  @Prop() freeTiers?: IFreeTiers;
  @Emit("chooseBundle") onChooseBundle() {}

  get internalValue(): boolean {
    return this.value;
  }

  set internalValue(value: boolean) {
    this.$emit("input", value);
  }

  get pricings() {
    return this.product.pricings[this.billingInterval] ?? [];
  }

  get selectedPricingIndex() {
    return this.product.selectedPricingIndex < this.pricings.length
      ? this.product.selectedPricingIndex
      : 0;
  }

  get selectedPricing(): IPriceState {
    return this.pricings[this.selectedPricingIndex];
  }

  get localizedBenefits() {
    return this.product.benefits[
      this.$i18n.locale.toUpperCase() as keyof ILocalizedBenefits
    ];
  }

  get firstBenefit() {
    return this.localizedBenefits[0];
  }

  get benefitList(): string[] {
    return this.localizedBenefits.slice(1);
  }

  get billingIntervalString() {
    return {
      [BillingIntervalEnum.DAY]: `${this.$t("billing_interval_day")}`,
      [BillingIntervalEnum.MONTH]: `${this.$t("billing_interval_month")}`,
      [BillingIntervalEnum.WEEK]: `${this.$t("billing_interval_week")}`,
      [BillingIntervalEnum.YEAR]: `${this.$t("billing_interval_year")}`,
      [BillingIntervalEnum.ONETIME]: "",
    };
  }

  get buttonCopy() {
    return this.$t("global_cta_choose");
  }

  get price(): number {
    return (this.selectedPricing?.price ?? 0) / this.priceDivisor;
  }

  get priceDivisor(): number {
    return this.selectedPricing?.billingInterval === BillingIntervalEnum.YEAR
      ? 12
      : 1;
  }

  get priceCurrencyString(): string {
    return this.selectedPricing?.currency === CurrencyTypeEnum.USD ? "$" : "€";
  }

  get priceDecimal(): number {
    return this.price - this.priceInteger;
  }

  get priceDecimalString(): string {
    return this.priceDecimal > 0
      ? this.priceDecimal
          .toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          .replace(/0[.,]/g, "")
      : "";
  }

  get priceInteger(): number {
    return Math.trunc(this.price);
  }

  get priceIntegerString(): string {
    return this.priceInteger.toLocaleString();
  }

  get priceTimeframe(): string {
    return this.selectedPricing?.recurring &&
      this.selectedPricing?.billingInterval
      ? `/ ${this.billingIntervalString[BillingIntervalEnum.MONTH]}`
      : "";
  }

  get priceSectionHeader() {
    return this.$t("bundle_widget_caption");
  }

  get unitAmountLocaleString(): string {
    return this.selectedPricing?.unitAmount
      ? this.selectedPricing.unitAmount.toLocaleString()
      : "free";
  }

  get currentLangFreeTiers() {
    if (!this.freeTiers) return [];

    return (
      this.freeTiers[this.$i18n.locale.toUpperCase() as keyof IFreeTiers] || []
    );
  }

  get checkmarkBenefitIconSrc() {
    return benefitCheckmarkIconBlack;
  }

  get bundleSelectedSrc() {
    return iconWidgetSelectedBlack;
  }
}
