import { mixins } from "vue-class-component";
import { Component, Emit } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import ApiData from "../api-management/ApiData.mixin";
import { Loader } from "../shared/Loader.component";
import Table from "../shared/table/Table.component";
import ApiDataObjectTypeActions from "./ApiDataObjectTypeActions.component";
import ApiDataObjectTypeDataImport from "./ApiDataObjectTypeDataImport.component";
import { ApiDataObjectTypeFieldForm } from "./ApiDataObjectTypeFieldForm.component";
import { ApiDataObjectTypeFieldOptions } from "./ApiDataObjectTypeFieldOptions.component";
import { IItem } from "./ApiDataObjectTypeTableData.mixin";
import { ApiDataObjectTypeTableState } from "./ApiDataObjectTypeTableState.mixin";

@Component
export default class ApiDataObjectTypeTable extends mixins(
  ApiDataObjectTypeTableState,
  ApiData
) {
  _tsx!: tsx.DeclareProps<{
    objectType: ApiDataObjectTypeTableState["objectType"];
    schemaFieldTypes: ApiDataObjectTypeTableState["schemaFieldTypes"];
    customerApi: ApiDataObjectTypeTableState["customerApi"];
    dedicatedApi: ApiDataObjectTypeTableState["dedicatedApi"];
    objectStack: ApiDataObjectTypeTableState["objectStack"];
    apiState: ApiDataObjectTypeTableState["apiState"];
    apiSchema: ApiDataObjectTypeTableState["apiSchema"];
  }> &
    tsx.DeclareOnEvents<{
      onEditTableEntry: {
        dataEntry: IItem;
        objectTypeName: string;
      };
      onError: string;
      onFilterValueChange: undefined;
    }>;

  @Emit("filterValueChange") onFilterValueChange() {}

  private get requiresPublishing() {
    return !this.apiState.graphqlUrl;
  }

  private get items() {
    return this.listDataManager.data.map((item) => ({
      ...item,
    }));
  }

  render() {
    return (
      <div>
        <ApiDataObjectTypeDataImport
          fieldsConfiguration={this.fieldsConfiguration}
          key={this.objectType?.name}
          requiredCreateInputParams={this.requiredCreateInputParams}
          customerApi={this.customerApi}
          objectType={this.objectType}
          scopedSlots={{
            actions: (props) => (
              <ApiDataObjectTypeActions
                totalRows={this.listDataManager.totalRows}
                objectType={this.objectType}
                onAddEntry={this.onAddEntry}
                canAddItems={!this.requiresPublishing}
                canImport={!this.requiresPublishing}
                canFilter={!this.requiresPublishing}
                canDeleteItems={
                  !this.requiresPublishing &&
                  this.tableSelectionState.hasOnlyFullRowsSelected
                }
                canExport={
                  !this.requiresPublishing &&
                  this.tableSelectionState.hasAnySelection
                }
                isLoading={this.listDataManager.isLoading}
                onDeleteEntries={this.onDeleteEntries}
                onFilterValueChange={this.onFilterValueChange}
                onExport={this.onExportData}
                onImport={props.onImport}
                onReload={this.onReload}
                schemaFieldTypes={this.schemaFieldTypes}
                filterSchemaTypes={this.filterSchemaTypes}
              />
            ),
          }}
        >
          <Table
            hasMoreItems={!!this.listDataManager.listVariables?.nextToken}
            items={this.items}
            onPaginationChange={this.listDataManager.onPaginationChange}
            ref="table"
            fields={this.fields}
            withSelectionColumn
            withEditColumn
            tableSelectionState={this.tableSelectionState}
            tableEditingState={this.tableEditingState}
            selectedDataExtractor={this.selectedDataExtractor}
            onEditTableEntry={this.editTableEntry}
            paginationState={this.listDataManager.paginationState}
            onCellChange={this.handleCellChange}
            onSortToggle={this.handleSortOrderChange}
            scopedSlots={{
              addColumnForm: ({ cancel }) => (
                <ApiDataObjectTypeFieldForm
                  onCancel={cancel}
                  apiState={this.apiState}
                  activeObjectTypeName={this.objectType?.name}
                />
              ),
              configureColumnForm: ({ close, field }) => (
                <ApiDataObjectTypeFieldOptions
                  onClose={close}
                  apiState={this.apiState}
                  typeName={this.objectType?.name}
                  fieldName={field.field.key}
                />
              ),
            }}
          >
            {this.requiresPublishing ? (
              <div>{this.$t("api_details_data_publishing_hint")}</div>
            ) : this.listDataManager.isLoading || !this.schemaFieldTypes ? (
              <Loader data-testid="tableLoader" />
            ) : null}
          </Table>
        </ApiDataObjectTypeDataImport>
      </div>
    );
  }
}
