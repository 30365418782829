import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";

import backIconBlack from "@/assets/img/icon-back-black.svg";
import iconCheckmarkLarge from "@/assets/img/icon-checkmark-large.svg";
import closeIconBlack from "@/assets/img/icon-close-black.svg";
import iconRightCheckmark from "@/assets/img/icon-right-chevron.svg";
import { BForm } from "@/lib/typed-bootstrap";
import { IFilterSchemaExplorerType, IFilterValueState } from "@/models/filters";
import FormInputWithLabel from "../forms/inputs/FormInputWithLabel.component";
import styles from "./ApiDataFilter.component.module.scss";

const backIconSrc = backIconBlack;
const closeIconSrc = closeIconBlack;

@Component
export default class ApiDataFilter extends Vue {
  _tsx!: tsx.DeclareProps<{
    value: ApiDataFilter["value"];
    items: ApiDataFilter["items"];
    disabled: ApiDataFilter["disabled"];
  }> &
    tsx.DeclareOnEvents<{
      onInput: IFilterValueState;
      onApplyFilter: void;
    }>;
  @Prop({ required: true }) value: IFilterValueState;
  @Prop({ required: true }) items: IFilterSchemaExplorerType[];
  @Prop({ required: true, default: true }) disabled: boolean;

  @Emit("input") onInput(_value: IFilterValueState) {}
  @Emit("applyFilter") onApplyFilter() {}

  $scopedSlots!: tsx.InnerScopedSlots<{
    button: { onClick: () => void; disabled: boolean };
    preview: {
      onClick: () => void;
      selectedFieldName: string;
      selectedOpName?: string;
      filterValue?: string;
    };
  }>;
  $refs!: {
    formInputComponent: FormInputWithLabel;
    filterDropdown: HTMLElement;
  };

  private isOpen = false;
  private get headerCopies() {
    return [
      this.$t("filters.selectField").toString(),
      this.$t("filters.selectOperator").toString(),
      this.$t("filters.filterBy").toString(),
    ];
  }
  private navigationStack: any[] = [];
  private filterValue = "";

  private get filterOps() {
    return this.selectedField?.ops;
  }

  private get filterExpression(): IFilterValueState {
    return this.selectedField?.name
      ? {
          [this.selectedField?.name]: {
            [this.selectedFilterOp]: this.filterValue,
          },
        }
      : {};
  }

  private get dropdownHeader(): string {
    return (
      this.headerCopies[Math.max(0, this.navigationStack.length - 1)] ?? ""
    );
  }

  private get hasPreviousStackItem(): boolean {
    return this.navigationStack.length > 1;
  }

  private get selectedField(): IFilterSchemaExplorerType | undefined {
    return this.items?.filter((type) => {
      return type.selected;
    })[0];
  }

  private get selectedFieldName() {
    return this.selectedField?.name ?? "";
  }

  private get selectedOp() {
    return this.selectedField?.ops.filter((op) => {
      return op.selected;
    })[0];
  }

  private get selectedOpName() {
    return this.selectedOp?.name ?? "";
  }

  private get selectedFilterOp() {
    return this.selectedOp?.op ?? "";
  }

  private get stackItems() {
    return this.navigationStack[this.navigationStack.length - 1];
  }

  private get showFormInput(): boolean {
    return this.stackItems.length === 0;
  }

  private get isFilterExpressionEmpty(): boolean {
    return Object.keys(this.value).length === 0;
  }

  private get hasPartialFilterExpression(): boolean {
    return Object.keys(this.filterExpression).length > 0;
  }

  @Watch("isOpen")
  isOpenWatcher() {
    if (!this.isOpen && this.isFilterExpressionEmpty) {
      this.resetNavigationStack();
    }
  }

  created() {
    this.navigationStack.push(this.items);
  }

  mounted() {
    window.addEventListener("click", this.onClick, true);
    window.addEventListener("keydown", this.onKeydown);
  }

  beforeDestroy() {
    window.removeEventListener("click", this.onClick, true);
    window.removeEventListener("keydown", this.onKeydown);
  }

  private onKeydown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.close();
    }
  }

  private onClick(event: Event) {
    const path = event.composedPath ? event.composedPath() : undefined;
    const dropdownHtmlElement = this.$refs.filterDropdown;

    if (path && dropdownHtmlElement && !path.includes(dropdownHtmlElement)) {
      this.close();
    }
  }

  private showFormField() {
    this.navigationStack.push([]);
  }

  @Watch("navigationStack")
  onNavigationStackChange() {
    if (this.navigationStack.length === 3) {
      this.$refs.formInputComponent.onBeginEditing();
    }
  }

  private navigateForward(item: any) {
    item["selected"] = true;
    this.navigationStack.length === 1
      ? this.navigationStack.push(this.filterOps)
      : this.showFormField();
  }

  private navigateBack() {
    this.navigationStack.pop();

    switch (this.navigationStack.length) {
      case 1:
        this.items.forEach((i) => (i.selected = false));
      /*eslint-disable no-fallthrough*/

      case 2:
        this.filterOps?.forEach((o) => (o.selected = false));
      /*eslint-disable no-fallthrough*/

      case 3:
        this.filterValue = "";
      /*eslint-disable no-fallthrough*/

      default:
        break;
    }
  }

  private onCancel() {
    this.isOpen = false;
  }

  private onClearFilter() {
    this.onInput({});
    this.onApplyFilter();
    this.resetNavigationStack();
  }

  private onSubmit() {
    this.onInput(this.filterExpression);
    this.onApplyFilter();
    this.isOpen = false;
  }

  private resetNavigationStack() {
    this.navigationStack = [this.items];
    this.filterValue = "";
    this.filterOps?.forEach((o) => (o.selected = false));
    this.items.forEach((i) => (i.selected = false));
  }

  private handleInput(value: string | number) {
    this.filterValue = value.toString();
  }

  private toggle() {
    this.isOpen = !this.isOpen;
  }

  private close() {
    this.isOpen = false;
  }

  render() {
    return (
      <div ref={"filterDropdown"} class={styles.container}>
        {this.hasPartialFilterExpression
          ? this.$scopedSlots.preview({
              onClick: this.onClearFilter,
              selectedFieldName: this.selectedFieldName,
              selectedOpName: this.selectedOpName,
              filterValue: this.filterValue,
            })
          : this.$scopedSlots.button({
              onClick: this.toggle,
              disabled: this.disabled,
            })}
        {this.isOpen && (
          <div class={styles.dropdown}>
            <div class={styles.dropdownHeader}>
              <button
                class={[
                  styles.backButton,
                  { [styles.hidden]: !this.hasPreviousStackItem },
                ]}
                type="button"
                onClick={tsx.modifiers.prevent(this.navigateBack)}
              >
                <img
                  class={styles.backButtonIcon}
                  src={backIconSrc}
                  alt="Close"
                />
              </button>
              <span class={styles.dropdownHeaderTitle}>
                {this.dropdownHeader}
              </span>
              <button class={styles.closeButton} onClick={this.close}>
                <img
                  class={styles.closeButtonIcon}
                  src={closeIconSrc}
                  alt="Close"
                />
              </button>
            </div>
            <div class={{ [styles.displayNone]: !this.showFormInput }}>
              <BForm accept-charset="UTF-8" role="form" novalidate>
                <FormInputWithLabel
                  ref="formInputComponent"
                  value={this.filterValue}
                  onInput={this.handleInput}
                  placeholderText="Enter Filter value"
                />
                <div class={styles.dropdownButtonsSection}>
                  <button
                    class={styles.dropdownCtaShallow}
                    type="button"
                    onClick={tsx.modifiers.prevent(this.onCancel)}
                  >
                    <img
                      class={styles.closeButtonIcon}
                      src={closeIconSrc}
                      alt="Close"
                    />
                  </button>
                  <button
                    class={styles.dropdownCta}
                    onClick={tsx.modifiers.stop.prevent(this.onSubmit)}
                    type="submit"
                  >
                    <div class={styles.barButtonIcon}>
                      <img
                        src={iconCheckmarkLarge}
                        style="visibility: hidden;"
                      />
                    </div>
                  </button>
                </div>
              </BForm>
            </div>
            <div
              class={[
                styles.dropdownContent,
                { [styles.displayNone]: this.showFormInput },
              ]}
            >
              {this.stackItems.map((item: any, index: number) => (
                <div key={`filter-item-${index}`} class={styles.dropdownItem}>
                  <a
                    class={styles.dropdownItemLink}
                    href="#"
                    onClick={tsx.modifiers.prevent(() =>
                      this.navigateForward(item)
                    )}
                  >
                    <span class={styles.dropwdownItemText}>{item.name}</span>
                    <div class={styles.dropdownItemIcon}>
                      <img src={iconRightCheckmark} />
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}
