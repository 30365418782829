import Vue from "vue";
import { Component } from "vue-property-decorator";
import styles from "./NoNav.layout.module.scss";
import DefaultFooter from "@/components/footer/DefaultFooter.component";

@Component
export default class Wizard extends Vue {
  render() {
    return (
      <div class="shared-layout">
        <div class={styles.container}>
          <router-view />
        </div>
        <DefaultFooter />,
      </div>
    );
  }
}
