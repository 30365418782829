import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import styles from "./TableView.component.module.scss";
import * as tsx from "vue-tsx-support";
import { Element } from "vue-tsx-support/types/base";
import { SelectionHeaderCell } from "./header/SelectionHeaderCell.component";
import { EditHeaderCell } from "./header/EditHeaderCell.component";
import { HeaderCell } from "./header/HeaderCell.component";
import { PlaceholderHeaderCell } from "./header/PlaceholderHeaderCell.component";
import { formattedTimeString } from "@/utils";
import {
  ICellChangePayload,
  ICellClickPayload,
  ICellDataContent,
  ICellIndex,
  IFieldConfig,
  IHeaderCellClickPayload,
  IItem,
  ITableField,
  ReservedFieldsEnum,
} from "./types";
import { SelectionVector } from "@/utils/selection-vector";
import { SelectionMatrix } from "@/utils/selection-matrix";
import { BodyRenderer } from "./body/body-renderer";
import { getDataColumnIndexOffset } from "./utils";
import { AddColumnHeaderCell } from "./header/AddColumnHeaderCell.component";
import { SortOrderType } from "@/generated/types";

const STICKY_COLUMNS_WIDTH = 72;
const HORIZONTAL_SCROLL_PADDING = 51;
const VERTICAL_SCROLL_PADDING = 16;

const formatContent = (
  field: ITableField,
  item: IItem
): string | Element | undefined => {
  const content = item[field.key];
  if (field.formatter) {
    return field.formatter(content, item);
  }

  if (field.key === "createdAt" || field.key === "updatedAt") {
    const dateString = formattedTimeString(content as string);
    if (dateString === "Invalid Date") {
      return content?.toString();
    }
    return dateString;
  }

  if (!content) return undefined;

  const stringContent = Array.isArray(content)
    ? content.join(", ")
    : content.toString();

  if (stringContent.length > 50) {
    return stringContent.slice(0, 50) + "...";
  }
  return stringContent;
};

@Component
export default class TableView extends Vue {
  _tsx!: tsx.DeclareProps<{
    fields: TableView["fields"];
    items: TableView["items"];
    startIndex?: TableView["startIndex"];
    selectedRows: TableView["selectedRows"];
    selectedColumns: TableView["selectedColumns"];
    selectedCells: TableView["selectedCells"];
    isFullTableSelected: TableView["isFullTableSelected"];
    withSelectionColumn?: TableView["withSelectionColumn"];
    withEditColumn?: TableView["withEditColumn"];
    withAddColumn?: TableView["withAddColumn"];
    cellInEditMode?: TableView["cellInEditMode"];
    cellInEditReplaceMode: TableView["cellInEditReplaceMode"];
  }> &
    tsx.DeclareOnEvents<{
      onCellClick: ICellClickPayload;
      onCellDblClick: ICellClickPayload;
      onHeaderCellClick: IHeaderCellClickPayload;
      onCellChange: ICellChangePayload;
      onSortToggle: SortOrderType;
    }>;
  @Prop({ required: true }) fields: ITableField[];
  @Prop({ required: true }) items: IItem[];
  @Prop({ default: 0 }) startIndex: number;
  @Prop({ required: true }) selectedRows: SelectionVector;
  @Prop({ required: true }) selectedColumns: SelectionVector;
  @Prop({ required: true }) selectedCells: SelectionMatrix;
  @Prop({ default: false }) isFullTableSelected: boolean;
  @Prop({ default: false }) withSelectionColumn: boolean;
  @Prop({ default: false }) withEditColumn: boolean;
  @Prop({ default: false }) withAddColumn: boolean;
  @Prop() cellInEditMode?: ICellIndex;
  @Prop() cellInEditReplaceMode?: ICellIndex;

  @Emit("cellClick") onCellClick(_event: ICellClickPayload) {}
  @Emit("cellDblClick") onCellDblClick(_event: ICellClickPayload) {}
  @Emit("headerCellClick") onHeaderCellClick(_event: IHeaderCellClickPayload) {}
  @Emit("cellChange") onCellChange(_event: ICellChangePayload) {}
  @Emit("sortToggle") onSortToggle(_value: SortOrderType) {}

  $scopedSlots!: tsx.InnerScopedSlots<{
    addColumnForm: { cancel: () => void };
    configureColumnForm: { close: () => void; field: IFieldConfig };
  }>;

  private bodyRenderer = new BodyRenderer();

  private get extendedFields(): IFieldConfig[] {
    const extendedFields: IFieldConfig[] = [];
    let columnIndex = 0;
    if (this.withSelectionColumn) {
      extendedFields.push({
        field: {
          key: ReservedFieldsEnum.selected,
          label: "",
        },
        stickyColumn: true,
        index: columnIndex,
      });
      columnIndex += 1;
    }
    if (this.withEditColumn) {
      extendedFields.push({
        field: {
          key: ReservedFieldsEnum.edit,
          label: "",
        },
        stickyColumn: true,
        index: columnIndex,
      });
      columnIndex += 1;
    }
    this.fields.forEach((field) => {
      extendedFields.push({
        field,
        index: columnIndex,
      });
      columnIndex += 1;
    });
    if (this.withAddColumn) {
      extendedFields.push({
        field: {
          key: ReservedFieldsEnum.addColumn,
          label: "",
        },
        index: columnIndex,
      });
    }

    extendedFields.push({
      field: {
        key: ReservedFieldsEnum.placeholder,
        label: "",
      },
      index: columnIndex,
    });

    return extendedFields;
  }

  private get computedSelectionState() {
    const isCellIndirectlySelected = (
      isRowSelected: boolean,
      rowIndex: number,
      colIndex: number
    ) =>
      isRowSelected ||
      this.isColumnSelected(colIndex) ||
      this.selectedCells.has({ x: colIndex, y: rowIndex });

    const dataColumnIndexOffset = getDataColumnIndexOffset(
      this.withSelectionColumn,
      this.withEditColumn
    );
    const lastDataColumnIndex =
      this.extendedFields.length - (this.withAddColumn ? 3 : 2);

    return this.items.map((_, rowIndex) => {
      const isRowSelected = this.isRowSelected(rowIndex);
      const isPrevRowSelected = this.isRowSelected(rowIndex - 1);
      const isNextRowSelected = this.isRowSelected(rowIndex + 1);
      const isLastRow = rowIndex === this.items.length - 1;

      return this.extendedFields.map((_, colIndex) => {
        const isFirstDataColumn = colIndex === dataColumnIndexOffset;
        const isLastDataColumn = colIndex === lastDataColumnIndex;

        return {
          isCellSelected: isCellIndirectlySelected(
            isRowSelected,
            rowIndex,
            colIndex
          ),
          isTopCellSelected: isCellIndirectlySelected(
            isPrevRowSelected,
            rowIndex - 1,
            colIndex
          ),
          isBottomCellSelected: isLastRow
            ? false
            : isCellIndirectlySelected(
                isNextRowSelected,
                rowIndex + 1,
                colIndex
              ),
          isLeftCellSelected: isCellIndirectlySelected(
            isRowSelected,
            rowIndex,
            colIndex - 1
          ),
          isRightCellSelected: isLastDataColumn
            ? false
            : isCellIndirectlySelected(isRowSelected, rowIndex, colIndex + 1),
          isLastRow,
          isRowSelected,
          isPrevRowSelected,
          isNextRowSelected,
          isFirstDataColumn,
          isPrevCellSelection: isFirstDataColumn && !this.withEditColumn,
          isInEditMode:
            colIndex === this.cellInEditMode?.column &&
            rowIndex === this.cellInEditMode.row,
          isInEditReplaceMode:
            colIndex === this.cellInEditReplaceMode?.column &&
            rowIndex === this.cellInEditReplaceMode.row,
          isFirstColumn: colIndex === 0,
        };
      });
    });
  }

  private get computedCellsData() {
    return this.items.map((item, rowIndex) => {
      const absoluteIndex = this.startIndex + rowIndex;
      return this.extendedFields.map(({ field }, colIndex) => ({
        handleClick: (ev: MouseEvent) => {
          ev.preventDefault();
          const isCmdPressed = ev.metaKey || ev.ctrlKey;
          const isShiftPressed = ev.shiftKey;

          this.onCellClick({
            item,
            index: absoluteIndex,
            relativeIndex: rowIndex,
            field: field.key,
            isCmdPressed,
            isShiftPressed,
          });
        },
        handleDblClick: (ev: MouseEvent) => {
          ev.preventDefault();
          this.onCellDblClick({
            item,
            index: absoluteIndex,
            relativeIndex: rowIndex,
            field: field.key,
            isCmdPressed: false,
            isShiftPressed: false,
          });
        },
        handleChange: (value: ICellDataContent) => {
          this.onCellChange({
            item,
            index: absoluteIndex,
            field: field.key,
            value,
          });
        },
        ref: `cell-${rowIndex}-${colIndex}`,
        content:
          field.key === ReservedFieldsEnum.selected
            ? (this.startIndex + rowIndex + 1).toString()
            : formatContent(field, item),
        rawContent: item[field.key],
        absoluteIndex: this.startIndex + rowIndex,
        cacheKey: `${item.id}-${field.key}`,
        item,
        field,
      }));
    });
  }

  private mapHeaderCellToComponent({ field, index }: IFieldConfig) {
    const handleClick = (event: MouseEvent) => {
      event.preventDefault();
      const isCmdPressed = event.metaKey || event.ctrlKey;
      const isShiftPressed = event.shiftKey;

      this.onHeaderCellClick({
        field: field.key,
        itemsOnAPageCount: this.items.length,
        isCmdPressed,
        isShiftPressed,
      });
    };

    const handleMouseDown = (ev: MouseEvent) => {
      if (ev.shiftKey) {
        ev.preventDefault();
      }
    };
    const isColumnSelected = this.isColumnSelected(index);

    switch (field.key) {
      case ReservedFieldsEnum.selected:
        return (
          <SelectionHeaderCell
            key={field.key}
            onClick={handleClick}
            isSelected={this.isFullTableSelected}
            onMousedown={handleMouseDown}
          />
        );
      case ReservedFieldsEnum.edit:
        return (
          <EditHeaderCell
            key={field.key}
            isSelected={this.isFullTableSelected}
          />
        );
      case ReservedFieldsEnum.addColumn:
        return (
          <AddColumnHeaderCell
            scopedSlots={{
              form: this.$scopedSlots.addColumnForm,
            }}
          />
        );
      case ReservedFieldsEnum.placeholder:
        return <PlaceholderHeaderCell key={field.key} />;
      default:
        return (
          <HeaderCell
            onClick={handleClick}
            key={field.key}
            text={field.label}
            iconSrc={field.iconSrc}
            inverseIconSrc={field.inverseIconSrc}
            isInactive={field.inactive}
            isSelected={isColumnSelected}
            isReadonly={field.readOnly}
            isConfigurable={field.configurable}
            sortOrder={field.sortOrder}
            onMousedown={handleMouseDown}
            scopedSlots={{
              configureColumnForm: ({ close }) =>
                this.$scopedSlots.configureColumnForm({
                  close,
                  field: { field, index },
                }),
            }}
            onSortToggle={this.onSortToggle}
          />
        );
    }
  }

  private isRowSelected(index: number) {
    return this.selectedRows.has(index);
  }

  private isColumnSelected(index: number) {
    return this.selectedColumns.has(index);
  }

  public scrollCellIntoView(cellIndex: ICellIndex) {
    const cell = this.$refs[
      `cell-${cellIndex.row}-${cellIndex.column}`
    ] as HTMLElement;
    const scrollContainer = this.$refs.tableScrollContainer as HTMLElement;
    if (!cell || !scrollContainer) {
      return;
    }
    const cellRect = cell.getBoundingClientRect();
    const scrollContainerRect = scrollContainer.getBoundingClientRect();
    if (!cellRect || !scrollContainerRect || !scrollContainer) {
      return;
    }

    if (cellRect.top < 0) {
      window.scrollBy(0, cellRect.top - VERTICAL_SCROLL_PADDING);
    } else if (cellRect.bottom > window.innerHeight) {
      window.scrollBy(
        0,
        cellRect.bottom - window.innerHeight + VERTICAL_SCROLL_PADDING
      );
    }

    if (cellRect.left < scrollContainerRect.left + STICKY_COLUMNS_WIDTH) {
      scrollContainer.scrollBy(
        cellRect.left -
          scrollContainerRect.left -
          STICKY_COLUMNS_WIDTH -
          HORIZONTAL_SCROLL_PADDING,
        0
      );
    } else if (cellRect.right > scrollContainerRect.right) {
      scrollContainer.scrollBy(
        cellRect.right - scrollContainerRect.right + HORIZONTAL_SCROLL_PADDING,
        0
      );
    }
  }

  render() {
    return (
      <div class={styles.wrapper} ref="tableScrollContainer">
        <table class={styles.table}>
          <thead class={styles.header}>
            {this.extendedFields.map((field) => {
              return this.mapHeaderCellToComponent(field);
            })}
          </thead>
          {this.items.length > 0 && (
            <tbody class={styles.body}>
              {this.bodyRenderer.render(
                this.$createElement,
                this.startIndex,
                this.computedCellsData,
                this.computedSelectionState
              )}
            </tbody>
          )}
        </table>
        {this.items.length === 0 && (
          <div class={styles.spinnerContainer}>{this.$slots.default}</div>
        )}
      </div>
    );
  }
}
