import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import { FormInputWithDropdown } from "../forms/inputs/FormInputWithDropdown.component";
import groupIconDark from "@/assets/img/group-icon-dark.svg";
import groupLockIconDark from "@/assets/img/group-lock-icon-dark.svg";
import lockIconDark from "@/assets/img/lock-icon-dark.svg";
import {
  IObjectTypeState,
  toggleObjectGroup,
  toggleObjectPublicRead,
  toggleObjectPublicCreate,
  toggleObjectPublicUpdate,
  toggleObjectPublicDelete,
} from "@/models/object-type";
import {
  FormNestedCheckbox,
  IFormNestedCheckboxState,
} from "../forms/inputs/FormNestedCheckbox.component";
import style from "./ObjectTypeNameInput.component.module.scss";
import {
  omitInitialDigits,
  removeSpecialCharacters,
  upperCaseFirstLetter,
} from "@/utils";
import {
  AuthMethodTypeEnum,
  FieldDirectiveEnum,
  ObjectDirectiveEnum,
} from "@/api/common-types";
import { BFormInput } from "@/lib/typed-bootstrap";

@Component
export class ObjectTypeNameInput extends Vue {
  _tsx!: tsx.DeclareProps<{
    value: ObjectTypeNameInput["value"];
    nameInputState: ObjectTypeNameInput["nameInputState"];
    apiAuthMode: ObjectTypeNameInput["apiAuthMode"];
  }> &
    tsx.DeclareOnEvents<{
      onPublicChange: FieldDirectiveEnum;
    }>;
  $scopedSlots!: tsx.InnerScopedSlots<{ action?: void; input?: string }>;
  $refs!: {
    input: HTMLInputElement;
  };

  @Prop({ required: true }) value: IObjectTypeState;
  @Prop({ required: true }) nameInputState: boolean | null;
  @Prop({ required: true }) apiAuthMode: AuthMethodTypeEnum;

  @Emit("publicChange") onPublicChange(_directive: FieldDirectiveEnum) {}

  mounted() {
    if (this.value?.name?.length === 0) {
      this.$refs.input?.focus();
    }
  }

  @Watch("value")
  onValueChanged() {
    this.$refs.input?.focus();
  }

  get isQueryPublic() {
    return this.value.directives.includes(ObjectDirectiveEnum.PUBLIC_READ);
  }

  get isGroup() {
    return (
      this.value.directives.includes(ObjectDirectiveEnum.PUBLIC_READ) ||
      this.value.directives.includes(ObjectDirectiveEnum.GROUP)
    );
  }

  get queryPublicState() {
    return {
      name: "Public",
      selected: this.isQueryPublic,
      children: [
        {
          name: "Group",
          selected: this.isGroup,
          children: [{ name: "Owner", selected: true, disabled: true }],
        },
      ],
    };
  }

  get createMutationPublicState() {
    return {
      name: "Public",
      selected: this.value.directives.includes(
        ObjectDirectiveEnum.PUBLIC_CREATE
      ),
    };
  }

  get updateMutationPublicState() {
    return {
      name: "Public",
      selected: this.value.directives.includes(
        ObjectDirectiveEnum.PUBLIC_UPDATE
      ),
    };
  }

  get deleteMutationPublicState() {
    return {
      name: "Public",
      selected: this.value.directives.includes(
        ObjectDirectiveEnum.PUBLIC_DELETE
      ),
    };
  }

  get iconSrc() {
    if (this.isQueryPublic) {
      return groupIconDark;
    }

    if (this.isGroup) {
      return groupLockIconDark;
    }

    return lockIconDark;
  }

  private inputFieldFormatter(value: string): string {
    return omitInitialDigits(
      upperCaseFirstLetter(removeSpecialCharacters(value))
    );
  }

  private handleQueryPublicState(value: IFormNestedCheckboxState) {
    if (this.queryPublicState.selected !== value.selected) {
      toggleObjectPublicRead(this.value);
      return;
    }

    if (
      this.queryPublicState.children[0].selected !==
      value.children?.[0].selected
    ) {
      toggleObjectGroup(this.value);
    }
  }

  private handleCreateMutationPublicState(value: IFormNestedCheckboxState) {
    if (this.createMutationPublicState.selected !== value.selected) {
      toggleObjectPublicCreate(this.value);
    }
  }

  private handleUpdateMutationPublicState(value: IFormNestedCheckboxState) {
    if (this.updateMutationPublicState.selected !== value.selected) {
      toggleObjectPublicUpdate(this.value);
    }
  }

  private handleDeleteMutationPublicState(value: IFormNestedCheckboxState) {
    if (this.deleteMutationPublicState.selected !== value.selected) {
      toggleObjectPublicDelete(this.value);
    }
  }

  private handleNameChange(name: string | number) {
    this.value.name = name.toString();
  }

  render() {
    const renderInput = (inputClass: string) => (
      <BFormInput
        type="text"
        class={[inputClass, "default__form-input"]}
        placeholder={this.$t("model_widget_model_name_placeholder").toString()}
        value={this.value.name}
        onInput={this.handleNameChange}
        max={64}
        required
        state={this.nameInputState}
        formatter={this.inputFieldFormatter}
        trim
        ref="input"
        data-testid="model-name"
      />
    );

    return this.apiAuthMode === AuthMethodTypeEnum.AWS_API_KEY ? (
      renderInput("")
    ) : (
      <FormInputWithDropdown
        id={`${this.value.id}-name`}
        iconSrc={this.iconSrc}
        titleContent={this.$t("model_widget_query_control").toString()}
        disclaimerContent={this.$t("model_widget_access_disclaimer").toString()}
        disabled={false}
        scopedSlots={{
          dropdownContent: () => (
            <div>
              <FormNestedCheckbox
                id={`${this.value.id}-query-access-action`}
                class={style.action}
                value={this.queryPublicState}
                onChange={this.handleQueryPublicState}
              />
              <p class={style.subHeader} role="presentation">
                {this.$t("model_widget_create_mutation_control").toString()}
              </p>
              <FormNestedCheckbox
                id={`${this.value.id}-create-mutation-access-action`}
                class={style.action}
                value={this.createMutationPublicState}
                onChange={this.handleCreateMutationPublicState}
              />
              <p class={style.subHeader} role="presentation">
                {this.$t("model_widget_update_mutation_control").toString()}
              </p>
              <FormNestedCheckbox
                id={`${this.value.id}-update-mutation-access-action`}
                class={style.action}
                value={this.updateMutationPublicState}
                onChange={this.handleUpdateMutationPublicState}
              />
              <p class={style.subHeader} role="presentation">
                {this.$t("model_widget_delete_mutation_control").toString()}
              </p>
              <FormNestedCheckbox
                id={`${this.value.id}-delete-mutation-access-action`}
                class={style.action}
                value={this.deleteMutationPublicState}
                onChange={this.handleDeleteMutationPublicState}
              />
            </div>
          ),
          input: renderInput,
        }}
      />
    );
  }
}
