import { FieldDirectiveEnum } from "@/api/common-types";
import { ISchemaExplorerType } from "@/api/schema";
import iconCloseBlack from "@/assets/img/icon-close-black.svg";
import filterIconDark from "@/assets/img/icon-filter.svg";
import {
  IFilterOpState,
  IFilterSchemaExplorerType,
  IFilterValueState,
} from "@/models/filters";
import { IObjectTypeState } from "@/models/object-type/types";
import { ApiDataModule } from "@/store";
import { sortObjectArrayByKey } from "@/utils";
import { PublishingTypeEnum } from "@graphapi-io/api-declaration";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import TableActionButton from "../shared/table/TableActionButton.component";
import TableActions from "../shared/table/TableActions.component";
import ApiDataFilter from "./ApiDataFilter.component";
import style from "./ApiDataObjectTypeActions.component.module.scss";

const filterIconSrc = filterIconDark;
const closeIconSrc = iconCloseBlack;

@Component
export default class ApiDataObjectTypeActions extends Vue {
  _tsx!: tsx.DeclareProps<{
    objectType: ApiDataObjectTypeActions["objectType"];
    totalRows: ApiDataObjectTypeActions["totalRows"];
    canDeleteItems: ApiDataObjectTypeActions["canDeleteItems"];
    canExport?: ApiDataObjectTypeActions["canExport"];
    canAddItems?: ApiDataObjectTypeActions["canAddItems"];
    canFilter?: ApiDataObjectTypeActions["canFilter"];
    canImport?: ApiDataObjectTypeActions["canImport"];
    isLoading?: ApiDataObjectTypeActions["isLoading"];
    schemaFieldTypes?: ApiDataObjectTypeActions["schemaFieldTypes"];
    filterSchemaTypes: ApiDataObjectTypeActions["filterSchemaTypes"];
  }> &
    tsx.DeclareOnEvents<{
      onAddEntry: void;
      onImport: void;
      onExport: void;
      onReload: void;
      onDeleteEntries: void;
      onFilterValueChange: void;
    }>;

  @Prop({ required: true }) objectType: IObjectTypeState;
  @Prop({ required: true }) totalRows: number;
  @Prop() schemaFieldTypes?: Record<string, ISchemaExplorerType>;
  @Prop({ required: true }) filterSchemaTypes:
    | ISchemaExplorerType[]
    | undefined;
  @Prop({ default: false }) canDeleteItems: boolean;
  @Prop({ default: false }) canExport: boolean;
  @Prop({ default: false }) canAddItems: boolean;
  @Prop({ default: false }) canImport: boolean;
  @Prop({ default: false }) canFilter: boolean;
  @Prop({ default: false }) isLoading: boolean;
  @Prop() hideImport?: boolean;
  @Prop() hideExport?: boolean;
  @Prop() hideDelete?: boolean;

  @Emit("addEntry") onAddEntry() {}
  @Emit("deleteEntries") onDeleteEntries() {}
  @Emit("import") onImport() {}
  @Emit("export") onExport() {}
  @Emit("reload") onReload() {}
  @Emit("filterValueChange") onFilterValueChange(value: IFilterValueState) {
    this.queryFilter = value;
  }

  private queryFilter: IFilterValueState = {};
  private idFilterObject = {
    name: "id",
    selected: false,
    ops: [
      {
        name: "equals",
        text: "equals",
        op: "eq",
        selected: false,
      },
    ],
  };
  private sortRangeFilterOps = [
    { name: "equals", text: "equals", op: "eq", selected: false },
    { name: "less than", text: "is less than", op: "lt", selected: false },
    { name: "less equal", text: "is less equal", op: "le", selected: false },
    {
      name: "greater than",
      text: "is greater than",
      op: "gt",
      selected: false,
    },
    {
      name: "greater equal",
      text: "is greater equal",
      op: "ge",
      selected: false,
    },
    {
      name: "begins with",
      text: "begins with",
      op: "beginsWith",
      selected: false,
    },
  ];
  private filterFieldsBlacklist: string[] = ["and", "not", "or"];
  private filterOpsMetaData: { [key: string]: { name: string; text: string } } =
    {
      ["eq"]: { name: "equals", text: "equals" },
      ["ne"]: { name: "not equals", text: "is not equals" },
      ["lt"]: { name: "less than", text: "is less than" },
      ["le"]: { name: "less equal", text: "is less equal" },
      ["gt"]: { name: "greater than", text: "is greater than" },
      ["ge"]: { name: "greater equal", text: "is greater equal" },
      ["beginsWith"]: { name: "begins with", text: "begins with" },
      ["contains"]: { name: "contains", text: "contains" },
      ["notContains"]: { name: "not contains", text: "does not contain" },
      ["attributeExists"]: {
        name: "attribute exists",
        text: "attribute exists",
      },
    };

  private get addEntryCopy(): string {
    return this.objectType?.name
      ? this.$t("global_cta_add_model_entry", {
          model: "",
        }).toString()
      : this.$t("global_cta_new").toString();
  }

  private get sortFieldName() {
    return (
      this.objectType.fields.find((field) =>
        field.directives?.includes(FieldDirectiveEnum.SORTBY)
      )?.name || "updatedAt"
    );
  }

  private get supportsFiltering() {
    return (
      !this.objectType?.publishingTypes ||
      this.objectType?.publishingTypes?.includes(PublishingTypeEnum.Queries)
    );
  }

  private get filterFields(): IFilterSchemaExplorerType[] {
    let filterFields = [];

    if (this.filterSchemaTypes) {
      filterFields =
        this.filterSchemaTypes?.reduce((acc: any[], field) => {
          if (field.name && !this.filterFieldsBlacklist.includes(field.name)) {
            const comparisonInputFields =
              this.schemaFieldTypes?.[field?.type?.name ?? ""]?.inputFields;

            if (comparisonInputFields && comparisonInputFields.length > 0) {
              if (this.sortFieldName === field.name) {
                acc.push({
                  ...field,
                  selected: false,
                  ops: this.sortRangeFilterOps,
                });
              } else {
                acc.push({
                  ...field,
                  selected: false,
                  ops:
                    comparisonInputFields?.reduce(
                      (acc: IFilterOpState[], field) => {
                        if (this.filterOpsMetaData[field.name] && field.name) {
                          acc.push({
                            ...this.filterOpsMetaData[field.name],
                            op: field.name,
                            selected: false,
                          });
                        }

                        return acc;
                      },
                      []
                    ) ?? [],
                });
              }
            }
          }

          return acc;
        }, []) ?? [];
    }

    return sortObjectArrayByKey(
      [{ ...this.idFilterObject, selected: false }, ...filterFields],
      "name"
    );
  }

  private handleApplyFilter() {
    const [fields] = Object.entries(this.queryFilter);
    if (fields && fields[0] === this.sortFieldName) {
      ApiDataModule.setActiveSortRange(this.queryFilter);
    } else {
      ApiDataModule.setActiveFilters(this.queryFilter);
      ApiDataModule.setActiveSortRange({});
    }
  }

  render() {
    return (
      <TableActions
        canDeleteItems={this.canDeleteItems}
        canExport={this.canExport}
        canImport={this.canImport}
        canAddItems={this.canAddItems}
        isLoading={this.isLoading}
        withImport
        withExport
        withDelete
        withDivider
        withReload
        onAddEntry={this.onAddEntry}
        onImport={this.onImport}
        onExport={this.onExport}
        onReload={this.onReload}
        onDeleteEntries={this.onDeleteEntries}
        addActionCopy={this.addEntryCopy}
      >
        <ApiDataFilter
          value={this.queryFilter}
          key={`${this.objectType?.name ?? ""}-filterbar-${
            this.filterFields.length
          }`}
          items={this.filterFields}
          disabled={!this.canFilter || this.filterFields.length === 0}
          onInput={this.onFilterValueChange}
          onApplyFilter={this.handleApplyFilter}
          scopedSlots={{
            button: ({ onClick, disabled }) => (
              <TableActionButton
                type="button"
                onClick={onClick}
                disabled={disabled || !this.supportsFiltering}
              >
                <img src={filterIconSrc} class={style.icon} />
                <span class={style.desktopOnly}>
                  {this.$t("global_cta_filter")}
                </span>
              </TableActionButton>
            ),
            preview: ({
              onClick,
              selectedFieldName,
              selectedOpName,
              filterValue,
            }) => (
              <TableActionButton isActive onClick={onClick}>
                <img src={filterIconSrc} class={style.icon} />
                <span class={style.desktopOnly}>
                  {selectedFieldName} {selectedOpName} {filterValue}
                </span>
                <span class={style.cancelFilterButton}>
                  <img src={closeIconSrc} class={style.icon} />
                </span>
              </TableActionButton>
            ),
          }}
        />
      </TableActions>
    );
  }
}
