import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import style from "./ProjectFlowEditor.view.module.scss";
import { reactProxyFactory } from "@/components/shared/ReactProxy.component";
import {
  Flows as FlowsReact,
  IFlowsProps,
} from "@graphapi-io/customer-components";
import { FunctionComponent } from "react";
import { IFlow } from "@/models/flow";
import { IApiState } from "@/models/api";
import { TriggerVariantEnum } from "@graphapi-io/flow-declaration";
import { updateFlow } from "@/models/flow";
import { upperCaseFirstLetter } from "@/utils";
import { ICopies } from "@/locales/copies";
import omit from "lodash/omit";
import { getFlowRunByFlowId } from "@/models/flow-run";
import { getFlowRunLogByFlowRunId } from "@/models/flow-run-log";

const Flows = reactProxyFactory<FunctionComponent<IFlowsProps>, IFlowsProps>(
  FlowsReact as FunctionComponent<IFlowsProps>
);

@Component
export default class ProjectFlowEditor extends Vue {
  @Prop({ default: null }) flow: IFlow | null;
  @Prop({ default: null }) api: IApiState | null;

  private async handleFlowDeclarationUpdate(declaration: IFlow["schema"]) {
    if (!this.flow) {
      return;
    }

    try {
      await updateFlow({
        id: this.flow.id,
        schema: JSON.stringify(declaration),
      });
    } catch (e) {
      // TODO: show this error somewhere
    }
  }

  private get flowProps(): IFlowsProps | undefined {
    if (!this.flow?.schema || !this.api?.schema) return undefined;

    const triggerCopies = this.$t(
      `flowEditor.triggers`
    ) as unknown as ICopies["flowEditor"]["triggers"];
    const stepsCopies = this.$t(
      `flowEditor.steps`
    ) as unknown as ICopies["flowEditor"]["steps"];

    return {
      declaration: this.flow.schema,
      apiDeclaration: JSON.parse(this.api.schema),
      theme: {
        "color-neutral": "var(--app-editor-color-neutral)",
        "color-primary": "var(--bg-color-default)",
        plate: {
          "color-border": "var(--border-color-dropdown)",
          spacing: "0.125rem",
        },
      },
      copies: {
        triggers: {
          ...triggerCopies,
          operationInputPlaceholder: triggerCopies.operationInputPlaceholder,
          [TriggerVariantEnum.TABLE_DATA_CHANGE]:
            triggerCopies.TABLE_DATA_CHANGE,
          INSERT: this.$t(`flowEditor.triggers.newEntry`).toString(),
          MODIFY: this.$t(`flowEditor.triggers.entryUpdate`).toString(),
          REMOVE: this.$t(`flowEditor.triggers.entryDeletion`).toString(),
          tableDataChangeEventName: (tableName: string | undefined) => {
            return upperCaseFirstLetter(
              `${triggerCopies.tableInputLabel} ${tableName}`
            );
          },
        },
        steps: {
          ...stepsCopies,
          stepParamPlaceholder: (label: string) =>
            this.$t("flowEditor.steps.stepParamPlaceholder", {
              paramName: label,
            }).toString(),
          stepParamJsPlaceholder: this.$t(
            "flowEditor.steps.stepParamJsPlaceholder"
          ).toString(),
          stepName: {
            ...omit(stepsCopies.stepName, ["createFont"]),
          } as ICopies["flowEditor"]["steps"]["stepName"],
          deleteField: this.$t("flowEditor.steps.deleteField").toString(),
          addField: this.$t("flowEditor.steps.addField").toString(),
        },
      },
      onFlowDeclarationUpdate: this.handleFlowDeclarationUpdate,
      queryFlowRuns: () => {
        return getFlowRunByFlowId(this.flow!.id, "network-only") as ReturnType<
          IFlowsProps["queryFlowRuns"]
        >;
      },
      queryFlowRunLogs: (flowRunId: string) => {
        return getFlowRunLogByFlowRunId(
          flowRunId,
          "network-only"
        ) as ReturnType<IFlowsProps["queryFlowRunLogs"]>;
      },
    };
  }

  render() {
    return this.flowProps ? (
      <Flows class={style.container} componentProps={this.flowProps} />
    ) : null;
  }
}
