import { PropType } from "vue";
import { BSpinner } from "@/lib/typed-bootstrap";
import * as tsx from "vue-tsx-support";

export const Loader = tsx.component({
  functional: true,
  props: {
    small: {
      type: Boolean,
    },
    variant: {
      type: String as PropType<"light" | "dark">,
    },
  },
  render(_, { props: { variant = "dark", small } }) {
    return (
      <BSpinner
        variant={variant}
        type="grow"
        label="Loading..."
        small={small}
      />
    );
  },
});
