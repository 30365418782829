import Vue from "vue";
import Navbar from "@/components/navbar/Navbar.component.vue";
import { Component } from "vue-property-decorator";
import style from "./Default.layout.module.scss";
import DefaultFooter from "@/components/footer/DefaultFooter.component";
@Component
export default class Default extends Vue {
  render() {
    return (
      <div class="shared-layout">
        <div class={style.container}>
          <Navbar />
          <div class="content">
            <router-view />
          </div>
        </div>
        <DefaultFooter />
      </div>
    );
  }
}
