import { Vue, Component, Prop } from "vue-property-decorator";
import styles from "./Checkbox.component.module.scss";
import * as tsx from "vue-tsx-support";
import { SyntheticEvent } from "vue-tsx-support/types/dom";
import iconCheckboxLight from "@/assets/img/icon-checkbox-light.svg";
import iconCheckboxCheckedLight from "@/assets/img/icon-checkbox-light-checked-reverse.svg";

const iconCheckbox = iconCheckboxLight;
const iconCheckboxChecked = iconCheckboxCheckedLight;

@Component
export class Checkbox extends Vue {
  _tsx!: tsx.DeclareProps<{
    checked: Checkbox["checked"];
    id: Checkbox["id"];
  }> &
    tsx.DeclareOnEvents<{
      onChange: SyntheticEvent<HTMLInputElement, Event>;
    }>;

  @Prop({ required: true }) checked: boolean;
  @Prop({ required: true }) id: string;

  render() {
    return (
      <label class={styles.container}>
        <input
          id={this.id}
          class={styles.input}
          type="checkbox"
          checked={this.checked}
          on={this.$listeners}
        />
        <img
          src={this.checked ? iconCheckboxChecked : iconCheckbox}
          class={styles.icon}
          data-testid={`${this.id}CheckboxIcon`}
        />
        <span class={styles.label}>{this.$slots.default}</span>
      </label>
    );
  }
}
