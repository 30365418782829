import { UserModule } from "@/store/modules/user";
import { UserRedirector } from "@/utils/auth-service/user-redirector";
import { OrgModule } from "@/store";
import { UserDataLoader } from "./user-data-loader";
import { ICredentials } from "@graphapi-io/customer-components";
import VueRouter from "vue-router";

export class AuthManager {
  private userRedirector = new UserRedirector(OrgModule);

  constructor(
    public userDataLoader: UserDataLoader,
    private router: VueRouter,
    private resetRouter: () => void
  ) {
    this.userDataLoader = userDataLoader;
  }

  public initialize() {
    this.userDataLoader.initialize();
    this.userDataLoader.loading.then(() => {
      UserModule.handleUserDataLoadingFinished();
    });
  }

  public async login(credentials: ICredentials) {
    await this.onUserAction(async () => {
      this.userDataLoader.signIn(credentials);
      await this.redirectUserBasedOnState();
    });
  }

  public async logout() {
    await this.onUserAction(async () => {
      this.userDataLoader.signOut();
      this.resetRouter();
      await this.router.replace({ name: "login" }).catch(() => {});
    });
  }

  public async refreshToken() {
    await this.onUserAction(async () => {
      this.userDataLoader.refreshToken();
      await this.redirectUserBasedOnState();
    });
  }

  public async handleCodeConfirmed(credentials: ICredentials) {
    await this.onUserAction(async () => {
      this.userDataLoader.confirmCode(credentials);
      await this.redirectUserBasedOnState();
    });
  }

  private async onUserAction(handler: () => Promise<void>) {
    UserModule.handleUserDataLoadingStarted();
    await handler();
    UserModule.handleUserDataLoadingFinished();
  }

  private async redirectUserBasedOnState() {
    const newState = await this.userDataLoader.loading;
    const redirect = this.userRedirector.getRedirectBasedOnState(
      newState,
      this.router.currentRoute
    );

    if (redirect) {
      this.router.push(redirect).catch(() => {});
    }
  }
}

export type IAuthManager = Required<AuthManager>;
