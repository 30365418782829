import Component from "vue-class-component";
import editIconWhite from "@/assets/img/icon-edit-white.svg";
import editIconBlack from "@/assets/img/icon-edit-black.svg";
import iconSlidersWhite from "@/assets/img/icon-sliders-white.svg";
import iconSlidersBlack from "@/assets/img/icon-sliders-black.svg";
import { Emit, Prop } from "vue-property-decorator";
import trashIconRed from "@/assets/img/icon-trash-red.svg";
import styles from "./ApiDataObjectTypeFieldOptions.component.module.scss";
import * as tsx from "vue-tsx-support";
import {
  Button,
  ButtonShapeEnum,
  ButtonSizeEnum,
  ButtonThemeEnum,
} from "../shared/Button.component";
import { IApiState } from "@/models";
import ApiTypesUpdater from "../object-type/ApiTypesUpdater.mixin";
import { ApiDataObjectTypeFieldForm } from "./ApiDataObjectTypeFieldForm.component";
import Dropdown from "../shared/Dropdown.component";
import { ApiDataObjectTypeFieldSettingsForm } from "./ApiDataObjectTypeFieldSettingsForm.component";

const editIcon = editIconBlack;
const editIconHover = editIconWhite;
const settingsIcon = iconSlidersBlack;
const settingsIconHover = iconSlidersWhite;

@Component
export class ApiDataObjectTypeFieldOptions extends ApiTypesUpdater {
  _tsx!: tsx.DeclareProps<{
    typeName: ApiDataObjectTypeFieldOptions["typeName"];
    apiState?: ApiDataObjectTypeFieldOptions["apiState"];
    fieldName: ApiDataObjectTypeFieldOptions["fieldName"];
  }> &
    tsx.DeclareOnEvents<{
      onClose: void;
    }>;

  @Prop({
    default: () => ({}),
  })
  apiState: IApiState;
  @Prop({ required: true }) typeName: string;
  @Prop({ required: true }) fieldName: string;

  @Emit("close") onClose() {}

  private isEditingField = false;
  private isEditingFieldSettings = false;

  private editField() {
    this.isEditingField = true;
  }

  private onCancelEdit() {
    this.isEditingField = false;
  }

  private editFieldSettings() {
    this.isEditingFieldSettings = true;
  }

  private onCancelEditFieldSettings() {
    this.isEditingFieldSettings = false;
  }

  private get type() {
    return this.apiState.types.find((type) => type.name === this.typeName);
  }

  private get field() {
    return this.type?.fields.find((field) => field.name === this.fieldName);
  }

  private get fieldIndex() {
    return (
      this.type?.fields.findIndex((field) => field.name === this.fieldName) ??
      -1
    );
  }

  private get typeIndex() {
    return this.apiState.types.findIndex((type) => type.name === this.typeName);
  }

  private async deleteField() {
    if (
      window.confirm(
        this.$t("api_details_data_delete_field_confirm", {
          fieldName: this.fieldName,
        }).toString()
      )
    ) {
      const type = this.apiState.types.find(
        (type) => type.name === this.typeName
      );
      const newFields =
        type?.fields.filter((field) => field.name !== this.fieldName) ?? [];
      const newState = {
        ...this.apiState,
        types: this.apiState.types.map((type) => {
          if (type.name === this.typeName) {
            return {
              ...type,
              fields: newFields,
            };
          }
          return type;
        }),
      };

      await this.updateApiDeclarationFromState(newState);
      if (this.serverSideErrors) {
        return;
      }
      this.onClose();
      this.apiState.types = newState.types;
    } else {
      this.onClose();
    }
  }

  public render() {
    return this.isEditingField ? (
      <ApiDataObjectTypeFieldForm
        onCancel={this.onCancelEdit}
        apiState={this.apiState}
        activeObjectTypeName={this.typeName}
        fieldName={this.fieldName}
      />
    ) : this.isEditingFieldSettings && this.type && this.field ? (
      <ApiDataObjectTypeFieldSettingsForm
        typeIndex={this.typeIndex}
        fieldIndex={this.fieldIndex}
        apiState={this.apiState}
        onCancel={this.onCancelEditFieldSettings}
        onSubmit={this.onClose}
      />
    ) : (
      <div>
        {!this.field?.readonly && (
          <Button
            class={styles.editButton}
            shape={ButtonShapeEnum.square}
            theme={ButtonThemeEnum.naked}
            size={ButtonSizeEnum.sm}
            onClick={this.editField}
            scopedSlots={{
              content: ({ isHovered }) => [
                <img
                  src={isHovered ? editIconHover : editIcon}
                  class={styles.icon}
                />,
                <span>{this.$t("api_details_data_edit_field")}</span>,
              ],
            }}
          />
        )}
        <Button
          class={styles.editButton}
          shape={ButtonShapeEnum.square}
          theme={ButtonThemeEnum.naked}
          size={ButtonSizeEnum.sm}
          onClick={this.editFieldSettings}
          scopedSlots={{
            content: ({ isHovered }) => [
              <img
                src={isHovered ? settingsIconHover : settingsIcon}
                class={styles.icon}
              />,
              <span>{this.$t("api_details_data_edit_field_settings")}</span>,
            ],
          }}
        />
        {!this.field?.readonly && <Dropdown.Divider />}
        {!this.field?.readonly && (
          <Button
            class={styles.deleteButton}
            shape={ButtonShapeEnum.square}
            theme={ButtonThemeEnum.naked}
            size={ButtonSizeEnum.sm}
            onClick={this.deleteField}
          >
            <img src={trashIconRed} class={styles.icon} />
            {this.$t("api_details_data_delete_field")}
          </Button>
        )}
      </div>
    );
  }
}
