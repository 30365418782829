import { DedicatedApi } from "@/api-client";
import { GenericApi } from "@/api-client/generic-api/generic-api";
import ApiDataObjectsData from "@/components/api-data/ApiDataObjectsData.component";
import { ApiDataObjectStack } from "@/components/api-data/ApiDataObjectStack.mixin";
import ApiManagementSectionHeader from "@/components/api-management/ApiManagementSectionHeader.component";
import DataEntryForm from "@/components/object-view/DataEntryForm.component";
import { ObjectStack } from "@/components/object-view/ObjectStack.component";
import ProjectSetupModelTemplates from "@/components/project-setup/ProjectSetupModelTemplates.component";
import Tabs from "@/components/shared/Tabs.component";
import { PublishingState, SortOrderType } from "@/generated/types";
import { ApiSchemaHandler } from "@/lib/apischema-handler";
import { BAlert, BContainer } from "@/lib/typed-bootstrap";
import UserPermissions from "@/mixins/UserPermissions.mixin";
import { IApiState, IObjectTypeState } from "@/models";
import { ApiSchema } from "@/models/api-schema";
import { ApiDataModule } from "@/store/modules/api-data";
import { persistanceService } from "@/utils/persistance-service";
import ApolloClient from "apollo-boost";
import _isEmpty from "lodash/isEmpty";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";

@Component
export default class ProjectDataExplorer extends Mixins(
  UserPermissions,
  ApiDataObjectStack
) {
  @Prop({ required: true }) value: IApiState;
  @Prop({ required: true }) apiClient: ApolloClient<unknown>;
  @Prop({ required: true }) apiSchemaHandler: ApiSchemaHandler | null;

  $refs!: {
    tabs: Tabs;
  };

  private errorMessage: string | null = null;
  private get objectTypes() {
    return this.value?.types;
  }

  private get selectedObjectType(): IObjectTypeState | null {
    return (
      this.objectTypes?.find((objectType: IObjectTypeState) => {
        return objectType.name === this.objectNameInHash;
      }) ?? null
    );
  }

  private get objectNameInHash() {
    return this.$route.hash.substring(1);
  }

  private get schemaFieldTypes() {
    return this.apiSchemaHandler?.inProgress || !this.apiSchemaHandler
      ? undefined
      : this.apiSchemaHandler?.schemaFieldTypes;
  }

  public get hashUpdateTrigger() {
    return `${JSON.stringify(this.value.types)}${this.objectNameInHash}`;
  }

  private async updateHash(objectType: IObjectTypeState) {
    try {
      if (this.objectNameInHash !== objectType.name) {
        const newState = {
          query: this.$route.query,
          path: this.$route.path,
          hash: objectType.name,
        };

        if (this.objectNameInHash) {
          await this.$router.push(newState);
        } else {
          await this.$router.replace(newState);
        }
        persistanceService.setActiveRouteKey(this.$route.fullPath);
        persistanceService.setActiveTable(objectType.name);
      }
    } catch (e) {
      // ignore
    }
  }

  private async handleFilterValueChange() {
    if (!this.activeFilters || _isEmpty(this.activeFilters)) {
      persistanceService.removeActiveFilters(this.objectNameInHash);
    } else {
      persistanceService.setActiveFilters(
        JSON.stringify(this.activeFilters),
        this.objectNameInHash
      );
    }

    if (!this.activeSortRange || _isEmpty(this.activeSortRange)) {
      persistanceService.removeActiveSortRange(this.objectNameInHash);
    } else {
      persistanceService.setActiveSortRange(
        JSON.stringify(this.activeSortRange),
        this.objectNameInHash
      );
    }
  }

  private get shouldShowTemplates() {
    return this.$route.query.showTemplates === "true";
  }

  private async hideTemplates(success: boolean) {
    try {
      await this.$router.replace({
        hash: success ? undefined : this.$route.hash,
        query: {
          ...this.$route.query,
          showTemplates: undefined,
        },
      });
    } catch (err) {
      // ignore
    }
  }

  private handleError(errorMessage: string) {
    this.errorMessage = errorMessage;
  }

  public beforeDestroy() {
    this.$emit("closeRightSidebar");
  }

  @Watch("hashUpdateTrigger", { immediate: true }) onObjectTypeDataChanged() {
    this.value?.types?.forEach((objectType: IObjectTypeState) => {
      objectType.selected = false;
    });

    if (!this.objectNameInHash && this.value.types) {
      const persistedObjectTypeName = persistanceService.getActiveTable();
      const objectType =
        this.value.types.find((objectType: IObjectTypeState) => {
          return objectType.name === persistedObjectTypeName;
        }) ?? this.value.types[0];
      if (objectType) {
        this.updateHash(objectType);
      }
    } else {
      const objectType = this.value?.types?.find(
        (objectType: IObjectTypeState) => {
          return objectType.name === this.objectNameInHash;
        }
      );
      if (objectType) {
        objectType.selected = true;
      }
    }
  }

  private get apiSchema() {
    return new ApiSchema(this.value, this.schemaFieldTypes);
  }

  private get customerApi() {
    if (this.schemaFieldTypes === undefined) {
      return undefined;
    }

    return this.value.state === PublishingState.IMPORTED
      ? new DedicatedApi(this.apiClient, this.schemaFieldTypes, this.apiSchema)
      : new GenericApi(
          this.apiClient,
          this.schemaFieldTypes,
          this.objectTypes,
          this.apiSchema,
          ""
        );
  }

  private get specificCustomerApi() {
    if (this.schemaFieldTypes === undefined) {
      return undefined;
    }

    return new DedicatedApi(
      this.apiClient,
      this.schemaFieldTypes,
      this.apiSchema
    );
  }

  private get activeFilters() {
    return ApiDataModule.activeFilters;
  }

  private get activeSortRange() {
    return ApiDataModule.activeSortRange;
  }

  public render() {
    return (
      <BContainer class="api__view-setup" fluid>
        <ApiManagementSectionHeader
          title={this.$t("api_details_section_title_data").toString()}
          description={this.$t(
            "api_details_section_description_data"
          ).toString()}
        />
        <BAlert show={!!this.errorMessage} variant="danger">
          {this.errorMessage}
        </BAlert>
        {this.selectedObjectType && (
          <ApiDataObjectsData
            selectedObjectType={this.selectedObjectType}
            apiState={this.value}
            schemaFieldTypes={this.schemaFieldTypes}
            customerApi={this.customerApi}
            specificCustomerApi={this.specificCustomerApi}
            objectStack={this.objectStack}
            onEditTableEntry={this.onEditTableEntry}
            onUpdateSelectedObjectType={this.updateHash}
            onFilterValueChange={this.handleFilterValueChange}
            onError={this.handleError}
            apiSchema={this.apiSchema}
          />
        )}
        {this.customerApi && (
          <ObjectStack
            stack={this.objectStack}
            customerApi={this.customerApi}
            objectTypes={this.value.types}
            dedicatedApi={this.specificCustomerApi}
            enumTypes={this.value.enums}
            apiSchema={this.apiSchema}
            scopedSlots={{
              view: (objectStackProps) => (
                <DataEntryForm
                  fieldsConfiguration={objectStackProps.fieldsConfiguration}
                  dataEntry={objectStackProps.data}
                  prevObjectTypeName={objectStackProps.prevObjectTypeName}
                  prevObjectId={objectStackProps.prevObjectId}
                  shouldCreateNewEntry={!objectStackProps.recordId}
                  customerApi={objectStackProps.customerApi}
                  objectType={objectStackProps.objectType}
                  initialListVariables={{
                    limit: 100,
                    filter: this.activeFilters,
                    nextToken: null,
                    sortOrder: SortOrderType.DESC,
                    sortRange: this.activeSortRange,
                  }}
                  onCancelDataCreation={this.onCancelDataCreation}
                  onCancelChildCreation={objectStackProps.handlePop}
                  onCreatedDataEntry={objectStackProps.handleReplace}
                  onConnectionClick={objectStackProps.handlePush}
                  onDataEntryError={objectStackProps.handleError}
                  objectTypes={this.value.types}
                />
              ),
            }}
          />
        )}
        <ProjectSetupModelTemplates
          visible={this.shouldShowTemplates}
          onHide={this.hideTemplates}
          apiData={this.value}
        />
      </BContainer>
    );
  }
}
