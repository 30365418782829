import { ApolloClient } from "apollo-boost";
import _cloneDeep from "lodash/cloneDeep";
import _pick from "lodash/pick";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import FormTable from "../forms/FormTable.component.vue";
import {
  FormModalEditingType,
  FormModalInputTypeEnum,
  IFormModalInputState,
} from "../modal/BaseFormModal.component";

// Mixins
import ErrorHandling from "@/mixins/ErrorHandling.mixin";
import UserPermissions from "@/mixins/UserPermissions.mixin";

// Utilities
import { DedicatedApi, ICustomerListVariables } from "@/api-client";
import {
  CustomerApiApiKeys,
  IApiKey,
} from "@/api-client/dedicated-api/api-keys";
import { SortOrderType } from "@/generated/types";
import { ApiSchemaHandler } from "@/lib/apischema-handler";
import { BAlert } from "@/lib/typed-bootstrap";
import { IApiState } from "@/models";
import { ApiSchema } from "@/models/api-schema";
import {
  convertDaysToSeconds,
  convertTimestampToDaysFromNow,
  getCurrentTimestampSeconds,
  getErrorMessage,
} from "@/utils";
import { ListDataManager } from "@/utils/list-data-manager";
import Vue from "vue";
import BaseFormModal from "../modal/BaseFormModal.component";
import { Loader } from "../shared/Loader.component";
import { SelectedDataExtractor } from "../shared/table/selected-data-extractor";
import { TableEditingState } from "../shared/table/table-editing-state";
import { TableSelectionState } from "../shared/table/table-selection-state";
import Table from "../shared/table/Table.component";
import TableActions from "../shared/table/TableActions.component";
import { ICellDataContent } from "../shared/table/types";

const getInitialVariables = (): ICustomerListVariables => ({
  limit: 50,
  nextToken: null,
  filter: undefined,
  sortOrder: SortOrderType.DESC,
  sortRange: undefined,
});

@Component({
  components: {
    FormTable,
    BaseFormModal,
  },
})
export default class ProjectManagementKeysSection extends mixins(
  ErrorHandling,
  UserPermissions
) {
  _tsx!: tsx.DeclareProps<{
    apiClient: ProjectManagementKeysSection["apiClient"];
    isVisible: ProjectManagementKeysSection["isVisible"];
    apiId: ProjectManagementKeysSection["apiId"];
    value: ProjectManagementKeysSection["value"];
    apiSchemaHandler: ProjectManagementKeysSection["apiSchemaHandler"];
  }>;

  @Prop({ required: true }) apiClient!: ApolloClient<unknown>;
  @Prop({ required: true }) private value: IApiState;
  @Prop({ required: true }) private apiSchemaHandler: ApiSchemaHandler | null;
  @Prop({ required: true }) isVisible!: boolean;
  @Prop({ required: true }) apiId!: string;

  private get listDataManager() {
    return Vue.observable(
      new ListDataManager<
        { items: IApiKey[]; nextToken?: string | null },
        ICustomerListVariables
      >(
        this.customerApiApiKeys.watchCloudFunctions.bind(
          this.customerApiApiKeys
        )
      )
    );
  }

  private tableSelectionState = Vue.observable(
    new TableSelectionState(true, false)
  );

  private get tableEditingState() {
    return Vue.observable(new TableEditingState(this.fields));
  }

  private selectedDataExtractor = new SelectedDataExtractor(
    [],
    [],
    true,
    false
  );

  private get schemaFieldTypes() {
    return this.apiSchemaHandler?.inProgress || !this.apiSchemaHandler
      ? {}
      : this.apiSchemaHandler?.schemaFieldTypes;
  }

  private get customerApi() {
    return new DedicatedApi(
      this.apiClient,
      this.schemaFieldTypes,
      new ApiSchema(this.value, this.schemaFieldTypes)
    );
  }

  private get customerApiApiKeys() {
    return new CustomerApiApiKeys(this.customerApi);
  }

  private get apiKeys() {
    return this.listDataManager.data.map((item) => ({ ...item }));
  }

  @Watch("apiSchemaHandler.inProgress", { immediate: true })
  protected onApiSchemaHandlerInitialized() {
    if (this.apiSchemaHandler && !this.apiSchemaHandler?.inProgress) {
      this.listDataManager.subscribeToList(getInitialVariables());
    }
  }

  editMode = FormModalEditingType.CREATE;
  apiKeyDataFormInput: IFormModalInputState[] | null = null;
  selectedApiKey: IApiKey | null = null;
  errorMessage = "";

  // Refs
  $refs!: {
    apiKeysTable: InstanceType<typeof FormTable>;
  };

  get defaultApiKeyDataFormInput() {
    return [
      {
        type: FormModalInputTypeEnum.STRING,
        label: this.$t("api_key_table_header_description").toString(),
        placeholderText: this.$t("form_placeholder_key_description").toString(),
        value: null,
        key: "description",
      },
      {
        type: FormModalInputTypeEnum.INT,
        label: this.$t("api_key_table_header_expires").toString(),
        placeholderText: this.$t("form_placeholder_expires").toString(),
        value: 365,
        min: 1,
        max: 365,
        key: "expires",
      },
    ];
  }

  get fields() {
    return [
      {
        key: "createdAt",
        label: this.$t("api_key_table_header_createdat").toString(),
        readOnly: true,
      },
      {
        key: "updatedAt",
        label: this.$t("api_key_table_header_updatedat").toString(),
        readOnly: true,
      },
      {
        key: "key",
        label: this.$t("api_key_table_header_key").toString(),
        readOnly: true,
      },
      {
        key: "description",
        label: this.$t("api_key_table_header_description").toString(),
        readOnly: true,
      },
      {
        key: "expires",
        label: this.$t("api_key_table_header_expires").toString(),
        formatter: (content: ICellDataContent) => {
          return `${convertTimestampToDaysFromNow(content as number)}`;
        },
        readOnly: true,
      },
    ];
  }

  onAddApiKey() {
    this.editMode = FormModalEditingType.CREATE;
    this.apiKeyDataFormInput = _cloneDeep(this.defaultApiKeyDataFormInput);
    this.$bvModal.show("form-modal");
  }

  onEditApiKey(row: number) {
    if (this.canPublishApi) {
      this.selectedApiKey = this.apiKeys[row];
      this.editMode = FormModalEditingType.UPDATE;
      this.apiKeyDataFormInput = _cloneDeep(this.defaultApiKeyDataFormInput);
      this.apiKeyDataFormInput[0].value = this.selectedApiKey.description;
      this.apiKeyDataFormInput[1].value = convertTimestampToDaysFromNow(
        this.selectedApiKey.expires
      );
      this.$bvModal.show("form-modal");
    }
  }

  async onCreateApiKey(): Promise<void> {
    const apiKeyDataInput =
      this.apiKeyDataFormInput?.reduce(
        (
          acc: Record<string, IFormModalInputState["value"]>,
          formInput: IFormModalInputState
        ) => {
          if (formInput.value) {
            if (formInput.key === "expires") {
              acc[formInput.key] =
                getCurrentTimestampSeconds() +
                convertDaysToSeconds(formInput.value);
            } else {
              acc[formInput.key] = formInput.value;
            }
          }
          return acc;
        },
        {}
      ) ?? {};
    apiKeyDataInput["key"] = "";

    try {
      this.customerApiApiKeys.createApiKey(
        apiKeyDataInput,
        getInitialVariables()
      );
    } catch (err) {
      this.errorMessage = (err as Error).message;
    }
  }

  async onUpdateApiKey(): Promise<void> {
    const apiKeyDataInput =
      this.apiKeyDataFormInput?.reduce(
        (acc: { [key: string]: any }, formInput: IFormModalInputState) => {
          if (formInput.value) {
            if (formInput.key === "expires") {
              acc[formInput.key] =
                getCurrentTimestampSeconds() +
                convertDaysToSeconds(formInput.value);
            } else {
              acc[formInput.key] = formInput.value;
            }
          }
          return acc;
        },
        {}
      ) ?? {};

    apiKeyDataInput["id"] = this.selectedApiKey?.id;
    apiKeyDataInput["key"] = this.selectedApiKey?.key;

    try {
      this.customerApiApiKeys.updateApiKey(apiKeyDataInput);
    } catch (err) {
      this.errorMessage = (err as Error).message;
    }
  }

  private async onDeleteEntries() {
    try {
      if (this.hasAdminPermissions) {
        const selectedEntries = this.listDataManager
          .entriesFromSelectionState(this.tableSelectionState)
          .map((entry) => _pick(entry, ["id", "key"]));

        if (
          window.confirm(
            this.$t("api_details_settings_apikey_delete_confirm", {
              numOfEntries:
                selectedEntries.length > 1
                  ? this.$t("global_many_entries", {
                      numOfEntries: selectedEntries.length,
                    })
                  : this.$t("global_single_entry"),
              typeName: "Flow",
            }).toString()
          )
        ) {
          await this.customerApiApiKeys.batchDeleteApiKeys(
            selectedEntries,
            getInitialVariables()
          );
        }
      } else {
        throw new Error(
          this.$t("error_api_management_requires_role").toString()
        );
      }
    } catch (err) {
      this.listDataManager.errorMessage = getErrorMessage(err);
    }
  }

  render() {
    return (
      <div>
        <BAlert show={this.showErrorMessage} variant="danger">
          {this.errorMessage}
        </BAlert>

        <TableActions
          canAddItems={this.canPublishApi}
          onAddEntry={this.onAddApiKey}
          withDelete
          canDeleteItems={this.tableSelectionState.hasOnlyFullRowsSelected}
          onDeleteEntries={this.onDeleteEntries}
          ref="tableActions"
        />
        <Table
          ref="table"
          data-testid="apiKeysTable"
          items={this.apiKeys}
          fields={this.fields}
          withEditColumn
          withSelectionColumn
          tableSelectionState={this.tableSelectionState}
          tableEditingState={this.tableEditingState}
          selectedDataExtractor={this.selectedDataExtractor}
          onPaginationChange={this.listDataManager.onPaginationChange}
          paginationState={this.listDataManager.paginationState}
          hasMoreItems={!!this.listDataManager.listVariables?.nextToken}
          onEditTableEntry={this.onEditApiKey}
        >
          {this.listDataManager.isLoading ? (
            <div>{this.$t("api_details_files_publishing_hint")}</div>
          ) : this.listDataManager.isLoading || !this.schemaFieldTypes ? (
            <Loader data-testid="tableLoader" />
          ) : null}
        </Table>
        <BaseFormModal
          title={this.$t("form_input_label_api_key").toString()}
          editMode={this.editMode}
          formData={this.apiKeyDataFormInput ?? []}
          onCreate={this.onCreateApiKey}
          onUpdate={this.onUpdateApiKey}
        />
      </div>
    );
  }
}
