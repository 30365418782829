import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import style from "./FormDropzone.component.module.scss";
import cloudIconLightSrc from "@/assets/img/icon-cloud-light.svg";
import * as tsx from "vue-tsx-support";
import { SyntheticEvent } from "vue-tsx-support/types/dom";
import { PasteHandler } from "@/mixins/PasteHandler.mixin";

const src = cloudIconLightSrc;

@Component
export class FormDropzone extends PasteHandler {
  _tsx!: tsx.DeclareProps<{
    id: FormDropzone["id"];
    accept?: FormDropzone["accept"];
  }> &
    tsx.DeclareOnEvents<{
      onFileUpload: File[];
      onInvalidFormat: void;
    }>;

  @Prop({ required: true }) id: string;

  @Emit("fileUpload") onFileUpload(_files: File[]) {}

  @Emit("invalidFormat") onInvalidFormat() {}

  private isDraggedOver = false;

  private onDragenter(e: DragEvent) {
    e.preventDefault();
    this.isDraggedOver = true;
  }

  private onDragover(e: DragEvent) {
    e.preventDefault();
  }

  private onDrop(event: DragEvent) {
    event.preventDefault();
    this.isDraggedOver = false;
    const files = this.filterAcceptedFormat(
      event?.dataTransfer?.files ? Array.from(event?.dataTransfer?.files) : []
    );
    if (files.length > 0) {
      this.onFileUpload(files);
    } else {
      this.onInvalidFormat();
    }
  }

  private onDragleave(e: DragEvent) {
    this.isDraggedOver =
      !!e.relatedTarget && this.$el.contains(e.relatedTarget as Node);
  }

  private handleFileUpload(ev: SyntheticEvent<HTMLInputElement, Event>) {
    const files = this.filterAcceptedFormat(
      ev.target.files ? Array.from(ev.target.files) : []
    );
    if (files.length > 0) {
      this.onFileUpload(Array.from(files));
    } else {
      this.onInvalidFormat();
    }
  }

  @Watch("pastedSupportedFiles", { immediate: true })
  onPastedData() {
    if (!this.pastedSupportedFiles || this.pastedSupportedFiles.length === 0) {
      return;
    }

    this.onFileUpload(this.pastedSupportedFiles);
  }

  render() {
    return (
      <div
        class={{ [style.dropzone]: true, [style.active]: this.isDraggedOver }}
        onDragenter={this.onDragenter}
        onDragover={this.onDragover}
        onDragleave={this.onDragleave}
        onDrop={this.onDrop}
      >
        <img src={src} />
        <div class={style.title}>{this.$t("form_dropzone.title")}</div>
        <div class={style.subtitle}>
          <input
            type="file"
            id={this.id}
            data-testid={this.id}
            onChange={this.handleFileUpload}
            class={style.fileInput}
            accept={this.accept}
          />
          <i18n path="form_dropzone.subtitle">
            <template slot="action">
              <label for={this.id} class={style.browse}>
                {this.$t("form_dropzone.browse")}
              </label>
            </template>
          </i18n>
        </div>
      </div>
    );
  }
}
