import { Component, Prop, Vue } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import styles from "./WizardStep.component.module.scss";
import closeIconWhite from "@/assets/img/icon-close-white.svg";
import { RawLocation } from "vue-router/types/router";

const closeIconSrc = closeIconWhite;

@Component
export default class WizardStep extends Vue {
  _tsx!: tsx.DeclareProps<{
    showCloseButton?: WizardStep["showCloseButton"];
    toLocationOnClose?: WizardStep["toLocationOnClose"];
  }>;

  @Prop({ required: false, default: true }) showCloseButton: boolean;
  @Prop({ required: false, default: { name: "home" } })
  toLocationOnClose: RawLocation;

  private handleClick() {
    this.$router.push(this.toLocationOnClose).catch(() => {
      /* Ignore */
    });
  }

  render() {
    return (
      <div class={styles.container}>
        <div class={styles.wizardName}>
          {this.showCloseButton && (
            <button
              class={styles.closeIconButton}
              role="button"
              onClick={this.handleClick}
            >
              <img
                class={styles.closeButtonIcon}
                src={closeIconSrc}
                alt="Close"
              />
            </button>
          )}
          <span class={styles.wizardNameText}>{this.$slots.wizardName}</span>
        </div>
        <h1 class={styles.header}>{this.$slots.header}</h1>
        <p class={styles.description}>{this.$slots.description}</p>
        <div class={styles.content}>{this.$slots.default}</div>
      </div>
    );
  }
}
