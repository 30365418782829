import * as tsx from "vue-tsx-support";
import style from "./SelectionHeaderCell.component.module.scss";
import iconCheckboxLight from "@/assets/img/icon-checkbox-light.svg";
import iconCheckboxLightChecked from "@/assets/img/icon-checkbox-light-checked.svg";
import { HeaderCell, IHeaderCellEvents } from "./HeaderCell.component";

const iconSrc = iconCheckboxLight;
const iconCheckedSrc = iconCheckboxLightChecked;

export const SelectionHeaderCell = tsx
  .componentFactoryOf<IHeaderCellEvents>()
  .create({
    functional: true,
    props: {
      isSelected: Boolean,
    },
    render(_, { props: { isSelected }, listeners }) {
      return (
        <HeaderCell
          class={{
            [style.cell]: true,
            [style.selected]: isSelected,
          }}
          on={listeners}
        >
          {isSelected ? (
            <img src={iconCheckedSrc} class={style.icon} />
          ) : (
            <img src={iconSrc} class={style.icon} />
          )}
        </HeaderCell>
      );
    },
  });
