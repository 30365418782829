import { mixins } from "vue-class-component";
import { Component, Watch } from "vue-property-decorator";
import { InvoiceApiResponse, invoicesQuery } from "@/api/invoice";
import { OrgModule } from "@/store/modules/org";
import UserPermissions from "@/mixins/UserPermissions.mixin";
import BillingDataForm from "@/components/org-management/BillingDataForm.component.vue";
import FormTable from "@/components/forms/FormTable.component.vue";
import downloadIconBlack from "@/assets/img/icon-download-black.svg";

@Component({
  components: { BillingDataForm, FormTable },
})
export default class OrgBillingDashboard extends mixins(UserPermissions) {
  invoices: InvoiceApiResponse[] = [];

  fields = [
    { key: "date", label: this.$t("org_billing_table_header_date") },
    { key: "currency", label: this.$t("org_billing_table_header_currency") },
    { key: "amountPaid", label: this.$t("org_billing_table_header_amount") },
    { key: "status", label: this.$t("org_billing_table_header_status") },
    { key: "invoicePdf", label: this.$t("org_billing_table_header_invoice") },
  ];

  totalRows = 1;
  currentDate = new Date();
  isLoading = false;
  errorMessage = "";

  downloadIconSrc = downloadIconBlack;

  get month(): string {
    return this.currentDate.toLocaleDateString(undefined, { month: "long" });
  }

  get year(): number {
    return this.currentDate.getFullYear();
  }

  get today(): string {
    return this.currentDate.toLocaleDateString();
  }

  get firstOfCurrentMonth(): Date {
    return new Date(Date.UTC(this.year, this.currentDate.getMonth(), 1));
  }

  get firstOfCurrentMonthString(): string {
    return this.firstOfCurrentMonth.toLocaleDateString();
  }

  get firstOfPreviousMonth(): Date {
    const previousMonth = new Date();
    previousMonth.setMonth(this.currentDate.getMonth() - 1);
    previousMonth.setDate(1);
    return previousMonth;
  }

  get firstOfPreviousMonthString(): string {
    return this.firstOfPreviousMonth.toLocaleDateString();
  }

  get lastOfPreviousMonth(): Date {
    const lastOfPreviousMonth = new Date(this.firstOfCurrentMonth);
    lastOfPreviousMonth.setDate(this.firstOfCurrentMonth.getDate() - 1);
    return lastOfPreviousMonth;
  }

  get lastOfPreviousMonthString(): string {
    return this.lastOfPreviousMonth.toLocaleDateString();
  }

  get firstOfThreeMonthAgo(): Date {
    const firstOfThreeMonthAgo = new Date(this.firstOfCurrentMonth);
    firstOfThreeMonthAgo.setMonth(this.firstOfCurrentMonth.getMonth() - 3);
    return firstOfThreeMonthAgo;
  }

  get firstOfThreeMonthAgoString(): string {
    return this.firstOfThreeMonthAgo.toLocaleDateString();
  }

  get orgId() {
    return OrgModule.id;
  }

  @Watch("orgId", { immediate: true })
  async fetchInvoices() {
    try {
      this.isLoading = true;
      const invoices = await invoicesQuery({
        billingAccountPaymentProviderId:
          OrgModule.billingAccount?.paymentProviderId ?? "",
      });
      this.invoices = await this.parseInvoices(
        invoices.data.listInvoicesByBillingAccount.items
      );
      this.totalRows = this.invoices.length;
    } catch (err) {
      this.errorMessage = (err as Error).message;
    } finally {
      this.isLoading = false;
    }
  }

  parseInvoices(invoices: InvoiceApiResponse[]): InvoiceApiResponse[] {
    return invoices.map((invoice) => {
      return {
        ...invoice,
        selected: false,
        date: new Date(invoice.date).toLocaleDateString(),
        amountPaid: invoice.amountPaid / 100,
      };
    });
  }
}
