enum PersistedItemKey {
  userLang = "user-lang",
  userCredentials = "cidp-claim",
  activeRoute = "active-route",
  apiName = "apiName",
  apiRegion = "apiRegion",
  apiAuth = "apiAuth",
  apiAuthMode = "apiAuthMode",
  apiAdditionalAuthModes = "apiAdditionalAuthModes",
  apiData = "apiData",
  apiGraphqlUrl = "apiGraphqlUrl",
  activeTable = "activeTable",
  activeOrg = "activeOrg",
  activeTheme = "activeTheme",
  activeFilters = "activeFilters",
  activeSortRange = "activeSortRange",
  selectedTemplate = "selectedTemplate",
}

export class PersistanceService {
  private prefix = "graphapi";

  private getKeyName(name: string) {
    return `${this.prefix}--${name}`;
  }

  private getItem(key: PersistedItemKey, namespace: string = "") {
    return localStorage.getItem(`${this.getKeyName(key)}${namespace}`);
  }

  private setItem(
    key: PersistedItemKey,
    value: string,
    namespace: string = ""
  ) {
    localStorage.setItem(`${this.getKeyName(key)}${namespace}`, value);
  }

  private removeItem(key: PersistedItemKey, namespace: string = "") {
    localStorage.removeItem(`${this.getKeyName(key)}${namespace}`);
  }

  private getSessionItem(key: PersistedItemKey) {
    return sessionStorage.getItem(this.getKeyName(key));
  }

  private setSessionItem(key: PersistedItemKey, value: string) {
    sessionStorage.setItem(this.getKeyName(key), value);
  }

  private removeSessionItem(key: PersistedItemKey) {
    sessionStorage.removeItem(this.getKeyName(key));
  }

  public removeCredentials() {
    this.removeSessionItem(PersistedItemKey.userCredentials);
  }

  public setCredentials(value: { username: string; password: string }) {
    this.setSessionItem(
      PersistedItemKey.userCredentials,
      btoa(JSON.stringify(value ?? {}))
    );
  }

  public getCredentials() {
    const jsonString = this.getSessionItem(PersistedItemKey.userCredentials);
    return jsonString
      ? JSON.parse(atob(jsonString))
      : { username: "", password: "" };
  }

  public removeUserLang() {
    this.removeItem(PersistedItemKey.userLang);
  }

  public setUserLang(value: string) {
    this.setItem(PersistedItemKey.userLang, value);
  }

  public getUserLang() {
    return this.getItem(PersistedItemKey.userLang);
  }

  public removeActiveRouteKey() {
    return this.removeItem(PersistedItemKey.activeRoute);
  }

  public setActiveRouteKey(value: string) {
    this.setItem(PersistedItemKey.activeRoute, value);
  }

  public getActiveRouteKey() {
    return this.getItem(PersistedItemKey.activeRoute);
  }

  public setApiName(value: string) {
    this.setItem(PersistedItemKey.apiName, value);
  }

  public getApiName() {
    return this.getItem(PersistedItemKey.apiName);
  }

  public setApiRegion(value: string) {
    this.setItem(PersistedItemKey.apiRegion, value);
  }

  public getApiRegion() {
    return this.getItem(PersistedItemKey.apiRegion);
  }

  public setApiAuth(value: string) {
    this.setItem(PersistedItemKey.apiAuth, value);
  }

  public getApiAuth() {
    return this.getItem(PersistedItemKey.apiAuth);
  }

  public setApiAuthMode(value: string) {
    this.setItem(PersistedItemKey.apiAuthMode, value);
  }

  public getApiAuthMode() {
    return this.getItem(PersistedItemKey.apiAuthMode);
  }

  public getAdditionAuthModes() {
    return this.getItem(PersistedItemKey.apiAdditionalAuthModes);
  }

  public setAdditionAuthModes(value: string) {
    this.setItem(PersistedItemKey.apiAdditionalAuthModes, value);
  }

  public removeApiData() {
    return this.removeItem(PersistedItemKey.apiData);
  }

  public setApiData(value: string) {
    this.setItem(PersistedItemKey.apiData, value);
  }

  public getApiData() {
    return this.getItem(PersistedItemKey.apiData);
  }

  public getApiGraphqlUrl() {
    return this.getItem(PersistedItemKey.apiGraphqlUrl);
  }

  public clearApiPersistance() {
    [
      PersistedItemKey.apiName,
      PersistedItemKey.apiRegion,
      PersistedItemKey.apiAuth,
      PersistedItemKey.apiAuthMode,
      PersistedItemKey.apiAdditionalAuthModes,
      PersistedItemKey.apiData,
      PersistedItemKey.apiGraphqlUrl,
    ].forEach((item) => this.removeItem(item));
  }

  public setActiveOrg(value: string) {
    return this.setItem(PersistedItemKey.activeOrg, value);
  }

  public getActiveOrg() {
    return this.getItem(PersistedItemKey.activeOrg);
  }

  public setActiveTheme(value: string) {
    return this.setItem(PersistedItemKey.activeTheme, value);
  }

  public getActiveTheme() {
    return this.getItem(PersistedItemKey.activeTheme);
  }

  public setActiveTable(value: string) {
    return this.setItem(PersistedItemKey.activeTable, value);
  }

  public getActiveTable() {
    return this.getItem(PersistedItemKey.activeTable);
  }

  public removeActiveTheme() {
    return this.removeItem(PersistedItemKey.activeTheme);
  }

  public getSelectedTemplate() {
    return this.getItem(PersistedItemKey.selectedTemplate);
  }

  public setSelectedTemplate(value: string) {
    return this.setItem(PersistedItemKey.selectedTemplate, value);
  }

  public getActiveFilters(activeTable: string) {
    return this.getItem(PersistedItemKey.activeFilters), activeTable;
  }

  public setActiveFilters(activeTable: string, value: string) {
    return this.setItem(PersistedItemKey.activeFilters, value, activeTable);
  }

  public removeActiveFilters(activeTable: string) {
    return this.removeItem(PersistedItemKey.activeFilters, activeTable);
  }

  public getActiveSortRange(activeTable: string) {
    return this.getItem(PersistedItemKey.activeSortRange, activeTable);
  }

  public setActiveSortRange(activeTable: string, value: string) {
    return this.setItem(PersistedItemKey.activeSortRange, value, activeTable);
  }

  public removeActiveSortRange(activeTable: string) {
    return this.removeItem(PersistedItemKey.activeSortRange, activeTable);
  }

  public clearWorkspace() {
    this.clearApiPersistance();
    this.removeItem(PersistedItemKey.activeOrg);
    this.removeItem(PersistedItemKey.activeRoute);
  }

  public clear() {
    const activeTheme = this.getActiveTheme();
    localStorage.clear();

    if (activeTheme) {
      this.setActiveTheme(activeTheme);
    }
  }
}

export const persistanceService = new PersistanceService();
