import Vue from "vue";
import Component from "vue-class-component";
import downArrowDark from "@/assets/img/down-chevron-dark.svg";
import style from "./Dropdown.component.module.scss";
import { Emit, Prop } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import Plate from "./Plate.component";
import styles from "./Dropdown.component.module.scss";

const downArrowIcon = downArrowDark;

const horizontalPaddingMedia: Record<string, number> = {
  "(min-width: 0px)": 20,
  "(min-width: 768px)": 35,
};

@Component
class SectionHeader extends Vue {
  render() {
    return <h3 class={style.sectionHeader}>{this.$slots.default}</h3>;
  }
}

@Component
class Element extends Vue {
  render() {
    return <div class={style.element}>{this.$slots.default}</div>;
  }
}

@Component
class Divider extends Vue {
  render() {
    return <div class={style.divider} />;
  }
}

@Component
class SectionText extends Vue {
  render() {
    return <p class={style.sectionText}>{this.$slots.default}</p>;
  }
}

@Component
export default class Dropdown extends Vue {
  _tsx!: tsx.DeclareProps<{
    toggleClass?: Dropdown["toggleClass"];
    dropdownMenuClass?: Dropdown["dropdownMenuClass"];
    leftPlateOffset?: Dropdown["leftPlateOffset"];
    topPlateOffset?: Dropdown["topPlateOffset"];
    buttonText?: Dropdown["buttonText"];
    anchor?: Dropdown["anchor"];
  }> &
    tsx.DeclareOnEvents<{
      onOpen: void;
      onClose: void;
    }>;
  $refs!: {
    button?: HTMLElement | undefined;
    plate?: Plate;
  };

  $scopedSlots!: tsx.InnerScopedSlots<{
    menuContent: { close: () => void };
  }>;

  @Prop({ default: "" }) buttonText: string;
  @Prop({ default: "" }) toggleClass: string | (string | object)[];
  @Prop({ default: "" }) dropdownMenuClass: string;
  @Prop({ default: 10 }) leftPlateOffset: number;
  @Prop({ default: 20 }) topPlateOffset: number;
  @Prop({ default: null }) anchor: HTMLElement | null;

  @Emit("open") onOpen() {}
  @Emit("close") onClose() {}

  static SectionHeader = SectionHeader;
  static SectionText = SectionText;
  static Element = Element;
  static Divider = Divider;

  private isOpen: boolean = false;

  mounted() {
    window.addEventListener("click", this.onClick, true);
    window.addEventListener("keydown", this.onKeydown);
  }

  beforeDestroy() {
    window.removeEventListener("click", this.onClick, true);
    window.removeEventListener("keydown", this.onKeydown);
  }

  private onKeydown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.close();
    }
  }

  private onClick(event: Event) {
    const path = event.composedPath ? event.composedPath() : undefined;
    const plateHtmlElement = this.$refs.plate?.$refs.plate;

    if (
      path &&
      plateHtmlElement &&
      !path.includes(plateHtmlElement) &&
      this.$refs.button &&
      !path.includes(this.$refs.button)
    ) {
      this.close();
    }
  }

  public close() {
    this.isOpen = false;
    this.onClose();
  }

  public open() {
    this.isOpen = true;
    this.onOpen();
  }

  private handleClick(ev: MouseEvent) {
    ev.stopPropagation();
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  render() {
    const hasButtonContent = !!this.$slots.buttonContent;
    const hasMenuContent = !!this.$scopedSlots.menuContent;

    return (
      <div>
        <button
          ref="button"
          class={[styles.toggleClass, this.toggleClass]}
          onClick={this.handleClick}
        >
          {hasButtonContent && this.$slots.buttonContent}
          {!hasButtonContent && this.buttonText}
          {!hasButtonContent && <img src={downArrowIcon} />}
        </button>
        {this.isOpen && (
          <Plate
            ref={"plate"}
            anchor={this.anchor || this.$refs.button}
            plateClassName={[styles.plate, this.dropdownMenuClass]}
            leftOffset={this.leftPlateOffset}
            topOffset={this.topPlateOffset}
            horizontalPaddingMedia={horizontalPaddingMedia}
          >
            {hasMenuContent
              ? this.$scopedSlots.menuContent({ close: this.close })
              : this.$slots.default}
          </Plate>
        )}
      </div>
    );
  }
}
