import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import sortIconDark from "@/assets/img/icon-sort-dark.svg";
import moreIcon from "@/assets/img/icon-more.svg";
import { IFieldState, nonSortableFieldTypes } from "@/models/object-type";
import style from "./ObjectTypeFieldNameInput.component.module.scss";
import { removeSpecialCharacters } from "@/utils";
import { FieldDirectiveEnum } from "@/api/common-types";
import { BFormInput } from "@/lib/typed-bootstrap";
import {
  FormNestedCheckbox,
  IFormNestedCheckboxState,
} from "../forms/inputs/FormNestedCheckbox.component";
import { FormInputWithDropdown } from "../forms/inputs/FormInputWithDropdown.component";

const sortIconSrc = sortIconDark;
const moreIconSrc = moreIcon;

@Component
export class ObjectTypeFieldNameInput extends Vue {
  _tsx!: tsx.DeclareProps<
    Pick<
      ObjectTypeFieldNameInput,
      | "value"
      | "index"
      | "isUnique"
      | "isSortByField"
      | "onSortByChange"
      | "onRequiredChange"
      | "onNameChange"
    >
  >;
  $scopedSlots!: tsx.InnerScopedSlots<{ action?: void; input?: string }>;

  @Prop({ required: true }) value: IFieldState;
  @Prop({ required: true }) index: number;
  @Prop({ required: true }) isUnique: boolean | null;
  @Prop({ required: true }) isSortByField: boolean;

  @Emit("sortByChange") onSortByChange(_selected: boolean) {}

  @Emit("requiredChange") onRequiredChange(_directive: FieldDirectiveEnum) {}

  @Emit("nameChange") onNameChange(_value: string | number) {}

  get sortFieldState(): IFormNestedCheckboxState {
    return {
      name: "Sort field",
      selected: this.isSortByField,
      disabled: this.isSortByField && this.value.name === "updatedAt",
    };
  }

  get requiredFieldState() {
    const hasRequiredDirective =
      this.value.directives?.includes(FieldDirectiveEnum.REQUIRED) ?? false;
    const hasRequiredOnCreateDirective =
      this.value.directives?.includes(FieldDirectiveEnum.REQUIRED_ON_CREATE) ??
      false;
    const hasRequiredOnUpdateDirective =
      this.value.directives?.includes(FieldDirectiveEnum.REQUIRED_ON_UPDATE) ??
      false;
    const hasRequiredOnDeleteDirective =
      this.value.directives?.includes(FieldDirectiveEnum.REQUIRED_ON_DELETE) ??
      false;

    return {
      name: this.$t("model_field_required").toString(),
      selected: hasRequiredDirective,
      children: [
        {
          name: this.$t("model_field_required_on_create").toString(),
          directive: FieldDirectiveEnum.REQUIRED_ON_CREATE,
          selected: hasRequiredDirective || hasRequiredOnCreateDirective,
        },
        {
          name: this.$t("model_field_required_on_update").toString(),
          directive: FieldDirectiveEnum.REQUIRED_ON_UPDATE,
          selected: hasRequiredDirective || hasRequiredOnUpdateDirective,
        },
        {
          name: this.$t("model_field_required_on_delete").toString(),
          directive: FieldDirectiveEnum.REQUIRED_ON_DELETE,
          selected: hasRequiredDirective || hasRequiredOnDeleteDirective,
        },
      ],
    };
  }

  get isNotSortable() {
    return !!(
      (this.value.readonly && this.value.name !== "updatedAt") ||
      (this.value.type &&
        (nonSortableFieldTypes as readonly string[]).includes(this.value.type))
    );
  }

  get isRequiredStateImmutable() {
    return this.value.readonly;
  }

  handleSortByChange(value: IFormNestedCheckboxState) {
    this.onSortByChange(value.selected);
  }

  handleRequiredChange(value: IFormNestedCheckboxState) {
    const { requiredFieldState } = this;

    if (value.selected !== requiredFieldState.selected) {
      this.onRequiredChange(FieldDirectiveEnum.REQUIRED);
      return;
    }

    if (!requiredFieldState.children || !value.children) return;

    for (let i = 0; i < requiredFieldState.children.length; i++) {
      if (
        requiredFieldState.children[i].selected !== value.children[i].selected
      ) {
        this.onRequiredChange(requiredFieldState.children[i].directive);
        return;
      }
    }
  }

  render() {
    const {
      value,
      index,
      sortFieldState,
      requiredFieldState,
      isNotSortable,
      isRequiredStateImmutable,
      isUnique,
    } = this;

    return (
      <FormInputWithDropdown
        id={`${value.type}-${index}`}
        iconSrc={sortFieldState.selected ? sortIconSrc : moreIconSrc}
        titleContent={this.$t("model_field_settings").toString()}
        disclaimerContent={this.$t(
          "model_field_settings_disclaimer"
        ).toString()}
        disabled={isNotSortable && isRequiredStateImmutable}
        scopedSlots={{
          dropdownContent: () => (
            <div>
              {!isNotSortable && (
                <FormNestedCheckbox
                  id={`${value.type}-${index}-sortAction`}
                  class={style.action}
                  value={sortFieldState}
                  onChange={this.handleSortByChange}
                />
              )}
              {!isRequiredStateImmutable && (
                <FormNestedCheckbox
                  id={`${value.type}-${index}-requiredAction`}
                  class={style.action}
                  value={requiredFieldState}
                  onChange={this.handleRequiredChange}
                />
              )}
            </div>
          ),
          input: (inputClass) => (
            <BFormInput
              type="text"
              class={[inputClass, "default__form-input"]}
              placeholder={this.$t("model_field_name_placeholder").toString()}
              value={value.name}
              onInput={this.onNameChange}
              max={64}
              required
              state={value.readonly ? null : isUnique}
              readonly={!!value.readonly}
              formatter={removeSpecialCharacters}
              trim
            />
          ),
        }}
      />
    );
  }
}
