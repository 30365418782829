import { GraphapiModule } from "@/store/modules/graphapi";
import { OrgModule } from "@/store/modules/org";
import { UserModule } from "@/store/modules/user";
import { persistanceService } from "../persistance-service";
import { UserStateEnum } from "./types";
import { getErrorMessage } from "..";
import { FormModule } from "@/store/modules/form";
import { Auth, ICredentials } from "@graphapi-io/customer-components";

export class AuthStateHandler {
  public auth: Auth;

  constructor(auth: Auth) {
    this.auth = auth;
  }

  public async initialize(): Promise<UserStateEnum> {
    try {
      const { user, session, userAttributes } = await this.auth.initialize();

      UserModule.handleSignInResponse({ user, session, userAttributes });

      return UserStateEnum.Authenticated;
    } catch (err) {
      UserModule.handleAuthError(getErrorMessage(err));
      return this.signOut();
    }
  }

  public async signIn(credentials: ICredentials): Promise<UserStateEnum> {
    try {
      const { user, session, userAttributes } = await this.auth.signIn(
        credentials
      );

      UserModule.handleSignInResponse({ user, session, userAttributes });

      return UserStateEnum.Authenticated;
    } catch (err) {
      if (
        (err as Record<string, string>).code === "UserNotConfirmedException"
      ) {
        await this.auth.resendSignUp(credentials.username);
        UserModule.handleValidCredentials(credentials);
        FormModule.handleValidCredentials(credentials);

        return UserStateEnum.NotConfirmed;
      } else {
        UserModule.handleAuthError(getErrorMessage(err));
        FormModule.setPassword("");
        return UserStateEnum.NotAuthenticated;
      }
    }
  }

  public async signOut(): Promise<UserStateEnum> {
    try {
      UserModule.handleSignOut();
      OrgModule.reset();
      GraphapiModule.handleSignOut();
      persistanceService.clear();
      await this.auth.signOut();
    } catch (err) {
      UserModule.handleAuthError(getErrorMessage(err));
    }

    return UserStateEnum.NotAuthenticated;
  }

  public async refreshToken() {
    try {
      const { user, session, userAttributes } = await this.auth.refreshToken();
      UserModule.handleSessionRefresh({ user, session, userAttributes });

      return UserStateEnum.Authenticated;
    } catch (err) {
      UserModule.handleAuthError(getErrorMessage(err));
      return this.signOut();
    }
  }
}

export type IAuthStateHandler = Required<AuthStateHandler>;
