import SessionExpiredModal from "@/components/modal/SessionExpiredModal.component.vue";
import ApiManagementLayout from "@/layouts/ApiManagement.layout";
import DefaultLayout from "@/layouts/Default.layout";
import OrgManagementLayout from "@/layouts/OrgManagement.layout";
import NoNavNoFooterLayout from "@/layouts/NoNavNoFooter.layout";
import { UserModule } from "@/store/modules/user";
import { VueConstructor } from "vue";
import { OnIdle } from "vue-plugin-helper-decorator";
import { Component, Vue } from "vue-property-decorator";
import "./App.scss";
import { LoadingScreen } from "./components/shared/LoadingScreen.component";
import AppManagement from "./layouts/AppManagement.layout";
import FlowManagement from "./layouts/FlowManagement.layout";
import NoNavLayout from "./layouts/NoNav.layout";

const layouts: Record<string, VueConstructor<Vue>> = {
  "default-layout": DefaultLayout,
  "org-layout": OrgManagementLayout,
  "sidebar-layout": ApiManagementLayout,
  "no-nav-layout": NoNavLayout,
  "app-layout": AppManagement,
  "flow-layout": FlowManagement,
  "no-nav-no-footer-layout": NoNavNoFooterLayout,
};

@Component
export default class App extends Vue {
  defaultLayout = "default";
  sessionExpiredModalId = "session-modal";

  get layoutName(): string {
    return (this.$route.meta?.layout || this.defaultLayout) + "-layout";
  }

  get Layout() {
    return layouts[this.layoutName] ?? DefaultLayout;
  }

  get isAuthenticated(): boolean {
    return UserModule.isAuthenticated;
  }

  get isUserDataLoadedFirstTime(): boolean {
    return UserModule.isFirstTimeLoading;
  }

  @OnIdle()
  public whenIdle() {
    if (UserModule.isAuthenticated) {
      this.$bvModal.show(this.sessionExpiredModalId);
    }
  }

  render() {
    return (
      <div id="app">
        <SessionExpiredModal />
        <this.Layout />
        {this.isUserDataLoadedFirstTime && <LoadingScreen />}
        <portal-target name="modals" />
      </div>
    );
  }
}
