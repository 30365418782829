import Vue from "vue";
import Navbar from "@/components/navbar/Navbar.component.vue";
import { Component, Prop } from "vue-property-decorator";
import styles from "./Breadcrumbs.layout.module.scss";
import Link from "@/components/shared/Link.component";
import { Dictionary } from "vue-router/types/router";
import * as tsx from "vue-tsx-support";
import DefaultFooter from "@/components/footer/DefaultFooter.component";

@Component
export default class BreadcrumbsLayout extends Vue {
  _tsx!: tsx.DeclareProps<{
    links: BreadcrumbsLayout["links"];
  }>;

  @Prop() links: {
    name: string;
    params: Dictionary<string>;
    label: string;
  }[];

  render() {
    return (
      <div class="shared-layout">
        <div class={styles.container}>
          <Navbar>
            <template slot="navigationLeft">
              <div class={styles.breadcrumbs}>
                {this.links.map((link, index) => [
                  <Link
                    to={{
                      name: link.name,
                      params: link.params,
                    }}
                    class={styles.link}
                    exactActiveClass={styles.activeLink}
                  >
                    {link.label}
                  </Link>,
                  index < this.links.length - 1 ? (
                    <span class={styles.divider}>/</span>
                  ) : undefined,
                ])}
              </div>
            </template>
          </Navbar>
          {this.$slots.default}
        </div>
        <DefaultFooter />
      </div>
    );
  }
}
