import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import { BContainer } from "@/lib/typed-bootstrap";
import { OrgModule } from "@/store";
import rigthArrowIconDark from "@/assets/img/right-arrow-dark.svg";
import styles from "./ApiManagementBreadcrumbs.component.module.scss";
import * as tsx from "vue-tsx-support";

const arrowSrc = rigthArrowIconDark;

@Component
export default class ApiManagementBreadcrumbs extends Vue {
  _tsx: tsx.DeclareProps<{
    apiName: ApiManagementBreadcrumbs["apiName"];
  }> &
    tsx.DeclareOnEvents<{
      onLinkClick: void;
      onToggleMobileDropdown: void;
    }>;
  @Prop({ required: true }) apiName: string;

  @Emit("linkClick") private onLinkClick() {}
  @Emit("toggleMobileDropdown") private onToggleMobileDropdown() {}

  private get orgId(): string {
    return OrgModule.id;
  }

  get activeNavItem(): string {
    return this.$route.meta?.localizedName ?? "";
  }

  render() {
    return (
      <BContainer class={styles.breadcrumbs}>
        <router-link
          to={{ name: "org-api-dashboard", params: { orgId: this.orgId } }}
          custom
          scopedSlots={{
            default: ({
              href,
              navigate,
            }: {
              href: string;
              navigate: () => void;
            }) => (
              <a href={href} onClick={navigate} class={styles.alwaysActive}>
                {this.$t("nav_secondary_apis")}
              </a>
            ),
          }}
          nativeOn={{
            click: this.onLinkClick,
          }}
        />
        <img src={arrowSrc} class={styles.rightArrow} />
        <div class={[styles.apiName, styles.alwaysActive]}>{this.apiName}</div>
        <img src={arrowSrc} class={styles.rightArrow} />
        <a
          href="#"
          onClick={this.onToggleMobileDropdown}
          class={styles.alwaysActive}
        >
          {this.activeNavItem}
        </a>
      </BContainer>
    );
  }
}
