import { ICustomerListByVariables } from "@/api-client";
import { IGraphqlApi } from "@/api-client/shared/types";
import rightChevronLight from "@/assets/img/icon-right-chevron-light.svg";
import { SortOrderType } from "@/generated/types";
import { BButton, BPagination } from "@/lib/typed-bootstrap";
import { IObjectTypeState } from "@/models";
import { ListDataManager } from "@/utils/list-data-manager";
import pluralize from "pluralize";
import Vue from "vue";
import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import FormGroupWithLabel from "../forms/inputs/FormGroupWithLabel.component";
import {
  Button,
  ButtonSizeEnum,
  ButtonThemeEnum,
} from "../shared/Button.component";
import ChildConnectionItem from "./ChildConnectionItem.component";
import style from "./DataEntryFormChildInput.component.module.scss";
import { IObjectStackPushEvent } from "./types";

const PER_PAGE = 5;

interface Item {
  id: string;
  [key: string]: any;
}

const rightChevronSrc = rightChevronLight;

@Component
export default class DataEntryFormChildInput extends Vue {
  _tsx!: tsx.DeclareProps<{
    childObjectType: DataEntryFormChildInput["childObjectType"];
    parentObjectType: DataEntryFormChildInput["parentObjectType"];
    id: DataEntryFormChildInput["id"];
    customerApi: DataEntryFormChildInput["customerApi"];
    parentId: DataEntryFormChildInput["parentId"];
  }> &
    tsx.DeclareOnEvents<{ onConnectionClick: IObjectStackPushEvent }>;

  @Prop({ required: true }) childObjectType: IObjectTypeState;
  @Prop({ required: true }) parentObjectType: IObjectTypeState;
  @Prop({ required: true }) id: string;
  @Prop({ required: true }) customerApi: IGraphqlApi;
  @Prop({ required: true }) parentId: string;

  @Emit("connectionClick") handleConnectionClick(_ev: IObjectStackPushEvent) {}

  private get listDataManager() {
    return Vue.observable(
      new ListDataManager<
        { items: Item[]; nextToken?: string | null },
        ICustomerListByVariables
      >((variables) =>
        this.customerApi.watchListConnections<Item>(
          this.childObjectType,
          this.parentObjectType,
          variables,
          this.childObjectType.fields.map((f) => f.name)
        )
      )
    );
  }

  private currentPage = 1;
  private latestPage = 1;
  private isExpanded = false;

  private get nextToken(): string | null {
    return this.listDataManager.listVariables?.nextToken ?? null;
  }

  private get expectedTotalRows(): number {
    return this.nextToken
      ? this.listDataManager.totalRows + PER_PAGE
      : this.listDataManager.totalRows;
  }

  get fetchListIndicator() {
    return `${this.parentId}-${this.isExpanded}`;
  }

  private get dataToShow() {
    const slice = this.listDataManager.data.slice(
      (this.currentPage - 1) * PER_PAGE,
      (this.currentPage - 1) * PER_PAGE + PER_PAGE
    );
    if (slice.length < PER_PAGE && this.currentPage > 1) {
      const placeholders = new Array<null>(PER_PAGE - slice.length).fill(null);
      return [...slice, ...placeholders];
    }

    return slice;
  }

  private onPaginationChange(page: number) {
    this.listDataManager.onPaginationChange({
      currentPage: page,
      perPage: PER_PAGE,
      pageSizes: [PER_PAGE],
    });
  }

  private toggleExpansion() {
    this.isExpanded = !this.isExpanded;
  }

  private createChild() {
    this.handleConnectionClick({
      recordId: "",
      objectTypeId: this.childObjectType?.id,
      objectTypeName: this.childObjectType?.name,
    });
  }

  @Watch("fetchListIndicator")
  onIsExpandedChange() {
    if (!this.listDataManager.fetchMore && this.isExpanded) {
      this.listDataManager.subscribeToList({
        nextToken: null,
        limit: PER_PAGE,
        filter: undefined,
        id: this.parentId,
        sortOrder: SortOrderType.DESC,
        sortRange: undefined,
      });
    }
  }

  render() {
    return (
      <FormGroupWithLabel id={this.id}>
        <div class={style.container}>
          <button
            class={style.expandButton}
            onClick={this.toggleExpansion}
            data-testid={`expand${this.childObjectType?.name}Connections`}
          >
            <img
              src={rightChevronSrc}
              class={{
                [style.indicatorIcon]: true,
                [style.expanded]: this.isExpanded,
              }}
            />
            <span class={style.objectType}>
              {pluralize(this.childObjectType?.name)}
            </span>
          </button>
          {this.isExpanded && (
            <div class={style.innerContainer}>
              {this.dataToShow.map((val, index) =>
                val ? (
                  <ChildConnectionItem
                    data={val}
                    scopedSlots={{
                      action: (className) => (
                        <BButton
                          onClick={() =>
                            this.handleConnectionClick({
                              recordId: val.id,
                              objectTypeName: this.childObjectType.name,
                              objectTypeId: this.childObjectType.id,
                            })
                          }
                          class={[className, style.linkIcon]}
                          aria-label="Child Link"
                          data-testid={`${this.childObjectType?.name}ConnectionLink${index}`}
                        />
                      ),
                    }}
                  />
                ) : (
                  <div
                    class={{
                      [style.placeholder]: true,
                      [style.hidden]: !this.listDataManager.isLoading,
                    }}
                    aria-hidden
                  />
                )
              )}
              {this.listDataManager.data.length === 0 &&
                !this.listDataManager.isLoading && (
                  <div class={style.noData}>
                    {this.$t("object_view_no_children", {
                      objectType: pluralize(this.childObjectType?.name),
                    })}
                  </div>
                )}
              <div class={style.footer}>
                <Button
                  size={ButtonSizeEnum.sm}
                  theme={ButtonThemeEnum.primary}
                  onClick={this.createChild}
                  data-testid={`add${this.childObjectType?.name}ChildConnection`}
                >
                  <div class="add-button__icon" />
                  <span>{this.$t("global_cta_add")}</span>
                </Button>
                {(this.listDataManager.data.length > 5 || this.nextToken) && (
                  <BPagination
                    class={style.pagination}
                    value={this.currentPage}
                    perPage={PER_PAGE}
                    totalRows={this.expectedTotalRows}
                    hide-goto-end-buttons
                    align="end"
                    limit={3}
                    onChange={this.onPaginationChange}
                  >
                    <template slot="prev-text">
                      <img src={rightChevronSrc} class={style.leftChevron} />
                    </template>
                    <template slot="next-text">
                      <img
                        src={rightChevronSrc}
                        data-testid={`${this.childObjectType?.name}ConnectionNextPage`}
                      />
                    </template>
                  </BPagination>
                )}
              </div>
            </div>
          )}
        </div>
      </FormGroupWithLabel>
    );
  }
}
