import { envRootLocalizedDomain } from "@/utils";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import styles from "./DefaultFooter.component.module.scss";
import LangSelect from "./LangSelect.component.vue";

@Component({
  components: {
    LangSelect,
  },
})
export default class DefaultFooter extends Vue {
  _tsx!: tsx.DeclareProps<{}>;

  get year(): number {
    return new Date().getFullYear();
  }

  get imprintUrl(): string {
    return `${envRootLocalizedDomain()}/imprint`;
  }

  get privacyUrl(): string {
    return `${envRootLocalizedDomain()}/privacy`;
  }

  get termsUrl(): string {
    return `${envRootLocalizedDomain()}/terms`;
  }

  render() {
    return (
      <footer class={styles.footer}>
        <div class={styles.content}>
          <div class={styles.left}>
            <div class={styles.copyright}>
              &copy; {this.year} GraphApi.io GmbH
            </div>
          </div>
          <div class={styles.right}>
            <ul class={styles.list}>
              <li class={styles.langSelectItem}>
                <LangSelect class={styles.langSelect} />
              </li>
              <li>
                <a href={this.imprintUrl} target="_blank">
                  {this.$t("global_imprint")}
                </a>
              </li>
              <li>
                <a href={this.termsUrl} target="_blank">
                  {this.$t("global_terms")}
                </a>
              </li>
              <li>
                <a href={this.privacyUrl} target="_blank">
                  {this.$t("global_privacy_policy")}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}
