import { BForm } from "@/lib/typed-bootstrap";
import { IApiState } from "@/models";
import { IObjectTypeState } from "@/models/object-type";
import {
  toggleObjectPublishingAll,
  toggleObjectPublishingMutations,
  toggleObjectPublishingQueries,
  toggleObjectPublishingSubscriptions,
} from "@/models/object-type/object-publishing-types";
import { PublishingTypeEnum } from "@graphapi-io/api-declaration";
import _cloneDeep from "lodash/cloneDeep";
import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import {
  FormNestedCheckbox,
  IFormNestedCheckboxState,
} from "../forms/inputs/FormNestedCheckbox.component";
import ApiTypesUpdater from "../object-type/ApiTypesUpdater.mixin";
import { Button, ButtonThemeEnum } from "../shared/Button.component";
import Dropdown from "../shared/Dropdown.component";
import { Loader } from "../shared/Loader.component";
import Modal from "../shared/Modal.component";
import style from "./ApiDataObjectTypeSettingsForm.component.module.scss";

@Component
export class ApiDataObjectTypeSettingsForm extends ApiTypesUpdater {
  _tsx!: tsx.DeclareProps<{
    apiState?: ApiDataObjectTypeSettingsForm["apiState"];
    typeName?: ApiDataObjectTypeSettingsForm["typeName"];
  }> &
    tsx.DeclareOnEvents<{
      onCancel: void;
      onSubmit: IObjectTypeState;
    }>;
  $scopedSlots!: tsx.InnerScopedSlots<{ action?: void; input?: string }>;

  @Prop({
    default: () => ({}),
  })
  apiState: IApiState;
  @Prop() typeName: string;

  @Emit("cancel") private onCancel() {}
  @Emit("submit") private onSubmit(_objectType: IObjectTypeState) {}

  private objectType: IObjectTypeState | null = null;

  @Watch("typeName", { immediate: true })
  @Watch("apiState")
  initObjectType() {
    const objectType = _cloneDeep(
      this.apiState.types.find(
        (objectType) => objectType.name === this.typeName
      )
    );
    this.objectType = objectType ?? null;
  }

  private get isPublishingAll() {
    return (
      !this.objectType?.publishingTypes &&
      !(this.objectType?.publishingTypes?.length === 0)
    );
  }

  private get tablePublishState() {
    return {
      name: this.$t("table_publish_state_all").toString(),
      selected: this.isPublishingAll,
      children: [
        {
          name: this.$t("table_publish_state_queries").toString(),
          selected:
            this.objectType?.publishingTypes?.includes(
              PublishingTypeEnum.Queries
            ) || this.isPublishingAll,
        },
        {
          name: this.$t("table_publish_state_mutations").toString(),
          selected:
            this.objectType?.publishingTypes?.includes(
              PublishingTypeEnum.Mutations
            ) || this.isPublishingAll,
          children: [
            {
              name: this.$t("table_publish_state_subscriptions").toString(),
              selected:
                (this.objectType?.publishingTypes?.includes(
                  PublishingTypeEnum.Subscriptions
                ) &&
                  this.objectType?.publishingTypes?.includes(
                    PublishingTypeEnum.Mutations
                  )) ||
                this.isPublishingAll,
            },
          ],
        },
      ],
    };
  }

  private get showErrors() {
    return !!this.serverSideErrors;
  }

  private handlePublishingStateChange(value: IFormNestedCheckboxState) {
    if (!this.objectType) {
      return;
    }

    if (this.tablePublishState.selected !== value.selected) {
      this.objectType = _cloneDeep(toggleObjectPublishingAll(this.objectType));
      return;
    }

    if (
      this.tablePublishState.children[0].selected !==
      value.children?.[0].selected
    ) {
      this.objectType = _cloneDeep(
        toggleObjectPublishingQueries(this.objectType)
      );
    }

    const oldMutationPublishingState = this.tablePublishState.children[1];
    const newMutationPublishingState = value.children?.[1];
    if (
      oldMutationPublishingState.selected !==
      newMutationPublishingState?.selected
    ) {
      this.objectType = _cloneDeep(
        toggleObjectPublishingMutations(this.objectType)
      );
    }

    const oldSubscriptionPublishingState =
      oldMutationPublishingState?.children?.[0];
    const newSubscriptionPublishingState =
      newMutationPublishingState?.children?.[0];
    if (
      oldSubscriptionPublishingState?.selected !==
      newSubscriptionPublishingState?.selected
    ) {
      this.objectType = _cloneDeep(
        toggleObjectPublishingSubscriptions(this.objectType)
      );
    }
  }

  private async handleSubmit(ev: Event) {
    ev.preventDefault();

    const newObjectTypes = this.apiState.types.map((objectType) => {
      if (this.objectType && objectType.name === this.typeName) {
        return this.objectType;
      }
      return objectType;
    });

    const newApiState = {
      ...this.apiState,
      types: newObjectTypes,
    };

    await this.updateApiDeclarationFromState(newApiState);

    if (!this.serverSideErrors && this.objectType) {
      this.onSubmit(this.objectType);
      this.apiState.types = newObjectTypes;
    }
  }

  render() {
    return (
      <BForm
        onSubmit={this.handleSubmit}
        role="form"
        novalidate
        class={style.form}
      >
        <p class={style.subHeader} role="presentation">
          {this.$t("table_publish_state_settings").toString()}
        </p>
        <FormNestedCheckbox
          id={`${this.objectType?.id}-table-publish-state-action`}
          class={style.action}
          value={this.tablePublishState}
          onChange={this.handlePublishingStateChange}
        />
        <Dropdown.Divider />
        <Modal.ActionButtons
          class={[style.actionButtons, { [style.withErrors]: this.showErrors }]}
        >
          <Button
            onClick={this.onCancel}
            class={style.button}
            theme={ButtonThemeEnum.naked}
            type="button"
          >
            {this.$t("global_cta_cancel")}
          </Button>
          <Button
            theme={ButtonThemeEnum.primary}
            class={style.button}
            data-testid="uploadCloudFunction"
            type="submit"
            disabled={this.showErrors}
          >
            {this.isLoading ? (
              <Loader />
            ) : this.typeName === undefined ? (
              this.$t("global_cta_create")
            ) : (
              this.$t("global_cta_save")
            )}
          </Button>
        </Modal.ActionButtons>
        <p class={style.disclaimer}>
          {this.$t("model_widget_access_disclaimer").toString()}
        </p>
      </BForm>
    );
  }
}
