import _uniq from "lodash/uniq";
import _without from "lodash/without";

import { PublishingTypeEnum } from "@graphapi-io/api-declaration";
import { IObjectTypeState } from "./types";

const allPublishingTypes = [
  PublishingTypeEnum.Queries,
  PublishingTypeEnum.Mutations,
  PublishingTypeEnum.Subscriptions,
];

const hasAllPublishingTypes = (
  publishingTypes: PublishingTypeEnum[] | undefined
) => {
  return !publishingTypes || _uniq(publishingTypes).length === 3;
};

const createUpdatePublishingTypes =
  (
    callback: (
      publishingTypes: PublishingTypeEnum[] | undefined
    ) => PublishingTypeEnum[] | undefined
  ) =>
  (object: IObjectTypeState) => {
    object.publishingTypes = callback(object.publishingTypes);
    return object;
  };

export const toggleObjectPublishingAll = createUpdatePublishingTypes(
  (publishingTypes) => {
    return publishingTypes ? undefined : [];
  }
);

export const toggleObjectPublishingQueries = createUpdatePublishingTypes(
  (publishingTypes) => {
    if (!publishingTypes) {
      return _without(allPublishingTypes, PublishingTypeEnum.Queries);
    }

    const hasQueries = publishingTypes?.includes(PublishingTypeEnum.Queries);
    if (hasQueries) {
      return _without(publishingTypes, PublishingTypeEnum.Queries);
    }

    const publishingTypesWithQueries = _uniq([
      ...(publishingTypes ?? []),
      PublishingTypeEnum.Queries,
    ]);
    return hasAllPublishingTypes(publishingTypesWithQueries)
      ? undefined
      : publishingTypesWithQueries;
  }
);

export const toggleObjectPublishingMutations = createUpdatePublishingTypes(
  (publishingTypes) => {
    if (!publishingTypes) {
      return _without(allPublishingTypes, PublishingTypeEnum.Mutations);
    }

    const hasMutations = publishingTypes?.includes(
      PublishingTypeEnum.Mutations
    );

    if (hasMutations) {
      return _without(
        publishingTypes,
        PublishingTypeEnum.Mutations,
        PublishingTypeEnum.Subscriptions
      );
    }

    const publishingTypesWithMutations = _uniq([
      ...(publishingTypes ?? []),
      PublishingTypeEnum.Mutations,
    ]);
    return hasAllPublishingTypes(publishingTypesWithMutations)
      ? undefined
      : publishingTypesWithMutations;
  }
);

export const toggleObjectPublishingSubscriptions = createUpdatePublishingTypes(
  (publishingTypes) => {
    if (!publishingTypes) {
      return _without(allPublishingTypes, PublishingTypeEnum.Subscriptions);
    }

    const hasSubscriptions = publishingTypes?.includes(
      PublishingTypeEnum.Subscriptions
    );

    if (hasSubscriptions) {
      return _without(publishingTypes, PublishingTypeEnum.Subscriptions);
    }

    const publishingTypesWithSubscriptions = _uniq([
      ...publishingTypes,
      PublishingTypeEnum.Subscriptions,
    ]);
    return hasAllPublishingTypes(publishingTypesWithSubscriptions)
      ? undefined
      : publishingTypesWithSubscriptions;
  }
);
