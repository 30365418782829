import React, { ComponentType } from "react";
import Vue from "vue";
import { Root, createRoot } from "react-dom/client";
import { Component, Prop, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";

export const reactProxyFactory = <
  IComponent extends ComponentType<IProps>,
  IProps extends {}
>(
  ReactComponent: IComponent
) => {
  @Component
  class ReactProxy extends Vue {
    $refs!: {
      reactRoot: HTMLElement;
    };

    _tsx!: tsx.DeclareProps<{
      componentProps: ReactProxy["componentProps"];
    }>;

    @Prop({ required: true }) componentProps: IProps;

    private reactRoot: Root | null = null;

    @Watch("componentProps", { immediate: true })
    onPropsChange() {
      if (!this.$refs.reactRoot) return;
      this.reactRoot = this.reactRoot || createRoot(this.$refs.reactRoot);
      this.reactRoot?.render(
        React.createElement(ReactComponent, this.componentProps)
      );
    }

    mounted() {
      this.onPropsChange();
    }

    render() {
      return <div ref="reactRoot" style={{ width: "100%" }} />;
    }
  }

  return ReactProxy;
};
