import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import moreIcon from "@/assets/img/icon-more.svg";
import { FormInputWithDropdown } from "../forms/inputs/FormInputWithDropdown.component";
import {
  getTypedBFormSelect,
  getTypedBFormSelectOption,
  IBFormSelectEvents,
} from "@/lib/typed-bootstrap";
import {
  hasCreateMutationType,
  hasDeleteMutationType,
  hasUpdateMutationType,
} from "@/models";
import {
  FormNestedCheckbox,
  IFormNestedCheckboxState,
} from "../forms/inputs/FormNestedCheckbox.component";
import { isNotNullable } from "@/utils";
import {
  MutationTypeEnum,
  PipelineStepEnum,
} from "@graphapi-io/api-declaration";

const BFormSelect = getTypedBFormSelect<PipelineStepEnum>();
const BFormSelectOption = getTypedBFormSelectOption<PipelineStepEnum | null>();

export type IExtendedMutationType = MutationTypeEnum | "All";

const moreIconSrc = moreIcon;

@Component
export class ObjectTypeTriggerStepSelect extends Vue {
  _tsx!: tsx.DeclareProps<{
    stepValue: ObjectTypeTriggerStepSelect["stepValue"];
    mutationTypes: ObjectTypeTriggerStepSelect["mutationTypes"];
    id: ObjectTypeTriggerStepSelect["id"];
  }> &
    tsx.DeclareOnEvents<
      IBFormSelectEvents<PipelineStepEnum> & {
        onConnectorChange: string;
        onMutationTypesChange: IExtendedMutationType;
      }
    >;

  @Prop({ required: true }) stepValue: PipelineStepEnum | null;
  @Prop({ required: true }) mutationTypes: MutationTypeEnum[];
  @Prop({ required: true }) id: string;

  @Emit("mutationTypesChange") onMutationTypesChange(
    _value: IExtendedMutationType
  ) {}

  private get hasCreate() {
    return hasCreateMutationType(this.mutationTypes);
  }

  private get hasUpdate() {
    return hasUpdateMutationType(this.mutationTypes);
  }

  private get hasDelete() {
    return hasDeleteMutationType(this.mutationTypes);
  }

  private get hasAll() {
    return this.hasCreate && this.hasUpdate && this.hasDelete;
  }

  private get stepOptions() {
    const postfix = this.hasAll
      ? this.$t("model_widget_triggers_all")
      : [
          this.hasCreate ? this.$t("model_widget_triggers_create") : null,
          this.hasUpdate ? this.$t("model_widget_triggers_update") : null,
          this.hasDelete ? this.$t("model_widget_triggers_delete") : null,
        ]
          .filter(isNotNullable)
          .join(", ");

    return [
      {
        value: PipelineStepEnum.BEFORE,
        text: `${this.$t("model_widget_triggers_before")} ${postfix}`,
      },
      {
        value: PipelineStepEnum.AFTER,
        text: `${this.$t("model_widget_triggers_after")} ${postfix}`,
      },
    ];
  }

  private get mutationTypesState() {
    return {
      name: this.$t("model_widget_triggers_on_all").toString(),
      selected: this.hasAll,
      children: [
        {
          name: this.$t("model_widget_triggers_on_create").toString(),
          selected: this.hasCreate,
          value: MutationTypeEnum.CREATE,
          disabled: this.hasCreate && !this.hasDelete && !this.hasUpdate,
        },
        {
          name: this.$t("model_widget_triggers_on_update").toString(),
          selected: this.hasUpdate,
          value: MutationTypeEnum.UPDATE,
          disabled: this.hasUpdate && !this.hasCreate && !this.hasDelete,
        },
        {
          name: this.$t("model_widget_triggers_on_delete").toString(),
          selected: this.hasDelete,
          value: MutationTypeEnum.DELETE,
          disabled: this.hasDelete && !this.hasUpdate && !this.hasCreate,
        },
      ],
    };
  }

  private handleMutationTypesChange(value: IFormNestedCheckboxState) {
    if (value.selected !== this.mutationTypesState.selected) {
      this.onMutationTypesChange("All");
      return;
    }

    if (!this.mutationTypesState.children || !value.children) return;

    for (let i = 0; i < this.mutationTypesState.children.length; i++) {
      if (
        this.mutationTypesState.children[i].selected !==
        value.children[i].selected
      ) {
        this.onMutationTypesChange(this.mutationTypesState.children[i].value);
        return;
      }
    }
  }

  render() {
    return (
      <FormInputWithDropdown
        id={this.id}
        iconSrc={moreIconSrc}
        titleContent={this.$t(
          "model_widget_triggers_mutation_title"
        ).toString()}
        disclaimerContent={this.$t(
          "model_widget_triggers_disclaimer"
        ).toString()}
        scopedSlots={{
          dropdownContent: () => (
            <FormNestedCheckbox
              id={`${this.id}-nestedCheckbox`}
              value={this.mutationTypesState}
              onChange={this.handleMutationTypesChange}
              class="mt-2"
            />
          ),
          input: (inputClass) => (
            <BFormSelect
              class={[inputClass, "default__form-input", "form-select__input"]}
              value={this.stepValue ?? null}
              options={this.stepOptions}
              required
              on={this.$listeners}
            >
              <template slot="first">
                <BFormSelectOption value={null} disabled>
                  {this.$t("api_details_data_types_select_trigger_step")}
                </BFormSelectOption>
              </template>
            </BFormSelect>
          ),
        }}
      />
    );
  }
}
