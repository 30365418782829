import { Vue, Component, Watch } from "vue-property-decorator";
import { getOrgApis, IOrgApiState } from "@/models/org";
import { OrgModule } from "@/store";
import plusIconBlack from "@/assets/img/plus-icon.svg";

@Component({})
export default class Dashboard extends Vue {
  apis: IOrgApiState[] = [];
  isLoading = false;

  plusIconSrc = plusIconBlack;

  get orgId() {
    return OrgModule.id;
  }

  get workspaceName() {
    return OrgModule.name;
  }

  @Watch("orgId", { immediate: true })
  async onOrgChange() {
    if (!OrgModule.id) return;
    this.isLoading = true;
    try {
      this.apis = await getOrgApis(OrgModule.id);
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  }
}
