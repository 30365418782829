import { Component, Watch } from "vue-property-decorator";
import { OrgModule } from "@/store/modules/org";
import { UserModule } from "@/store/modules/user";
import DropdownsState, {
  DropdownStateEnum,
} from "@/mixins/DropdownsState.mixin";
import {
  Button,
  ButtonShapeEnum,
  ButtonSizeEnum,
  ButtonThemeEnum,
} from "../shared/Button.component";
import { getOrgNames } from "@/models/org";
import downArrowBlack from "@/assets/img/down-chevron-dark.svg";
import avatarLogoBlack from "@/assets/img/avatar-black.svg";
import { userDataLoader } from "@/utils/auth-service";
import plusIconBlack from "@/assets/img/plus-icon.svg";
import plusIconWhite from "@/assets/img/plus-icon-light.svg";

@Component({
  components: { Button },
})
export default class NavbarDropdownDesktop extends DropdownsState {
  desktopToggleId = "desktopNavDropdown";

  userOrgs: { id: string; name: string }[] = [];

  avatarSrc = avatarLogoBlack;

  downArrowSrc = downArrowBlack;

  plusIconSrc = plusIconBlack;

  plusIconHoverSrc = plusIconWhite;

  get buttonConfig() {
    return {
      shape: ButtonShapeEnum.square,
      theme: ButtonThemeEnum.naked,
      size: ButtonSizeEnum.sm,
    };
  }

  get userOrgIds() {
    return Object.keys(UserModule.roles);
  }

  get isShown() {
    return this.dropDownState[this.desktopToggleId] === DropdownStateEnum.shown;
  }

  get orgId(): string {
    return OrgModule.id;
  }

  get orgName(): string {
    return OrgModule.name ?? "Unknown organization";
  }

  get userEmail(): string {
    return UserModule.email;
  }

  get userIsAuthenticated() {
    return UserModule.isAuthenticated;
  }

  @Watch("userOrgIds", { immediate: true })
  async getOrgNames() {
    if (this.userOrgIds.length > 0) {
      this.userOrgs = await getOrgNames(this.userOrgIds);
    } else {
      this.userOrgs = [];
    }
  }

  async onSelectOrg(orgId: string) {
    await UserModule.onUserAction(async () => {
      userDataLoader.switchOrg();
    });
    this.$router
      .push({
        name: "org-api-dashboard",
        params: {
          orgId,
        },
      })
      .catch(() => undefined);
  }

  onCreateOrg() {
    this.$router.push({ name: "workspace-setup" });
  }

  logout() {
    this.$authManager.logout();
  }

  @Watch("userIsAuthenticated")
  async onUserAuthChange(): Promise<void> {
    if (!this.userIsAuthenticated) {
      this.hideDropdown(this.desktopToggleId);
    }
  }
}
