import i18n from "@/i18n";
import { UserStateEnum } from "@/utils/auth-service/types";
import { UserDataLoader } from "@/utils/auth-service/user-data-loader";
import { UserRedirector } from "@/utils/auth-service/user-redirector";
import { persistanceService } from "@/utils/persistance-service";
import AccountSettings from "@/views/account/AccountSettings.view.vue";
import ChangeEmailForm from "@/views/account/ChangeEmailForm.view.vue";
import VerifyEmailForm from "@/views/account/VerifyEmailForm.view.vue";
import OrgApiDashboard from "@/views/org/OrgApiDashboard.view.vue";
import OrgBillingDashboard from "@/views/org/OrgBillingDashboard.view.vue";
import OrgSettings from "@/views/org/OrgSettings.view.vue";
import OrgUserInvite from "@/views/org/OrgUserInvite.view.vue";
import OrgUserManagement from "@/views/org/OrgUserManagement.view.vue";
import WorkspaceSetupName from "@/views/org/WorkspaceSetupName.view";
import ApiDetailsDataTypes from "@/views/projects/ApiDetailsDataTypes.view.vue";
import ApiSetupBilling from "@/views/projects/ApiSetupBilling.view.vue";
import ApiSetupPublishing from "@/views/projects/ApiSetupPublishing.view.vue";
import ApiApps from "@/views/projects/ProjectApps.view";
import ProjectAssetsExplorer from "@/views/projects/ProjectAssetsExplorer.view";
import ProjectBundlesOverview from "@/views/projects/ProjectBundlesOverview.view";
import ProjectCloudFunctionsExplorer from "@/views/projects/ProjectCloudFunctionsExplorer.view";
import ProjectDataExplorer from "@/views/projects/ProjectDataExplorer.view";
import ProjectFlowEditor from "@/views/projects/ProjectFlowEditor.view";
import ProjectFlows from "@/views/projects/ProjectFlows.view";
import ProjectLogs from "@/views/projects/ProjectLogs.view";
import ProjectPublishAuth from "@/views/projects/ProjectPublishAuth.view";
import ProjectPublishRegion from "@/views/projects/ProjectPublishRegion.view";
import ProjectRepublish from "@/views/projects/ProjectRepublish.view";
import ProjectSettings from "@/views/projects/ProjectSettings.view";
import ProjectSetupName from "@/views/projects/ProjectSetupName.view";
import ProjectSetupOverview from "@/views/projects/ProjectSetupOverview.view";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import VueRouter, { NavigationGuardNext, Route } from "vue-router";
import { RouteAuthRequirementEnum } from "./types";

const LoginView = () => import("@/views/login/Login.view");
const ForgotPasswordView = () => import("@/views/login/ForgotPassword.view");
const SignupView = () => import("@/views/signup/Signup.view");
const SignupCodeEntryView = () => import("@/views/signup/SignupCodeEntry.view");
const ApiDetailsQueryExplorer = () =>
  import("@/views/projects/ProjectQueryExplorer.view");
const ProjectAppEditor = () => import("@/views/projects/ProjectAppEditor.view");
const ChangePasswordView = () => import("@/views/account/ChangePassword.view");

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteUpdate",
  "beforeRouteLeave",
]);

Vue.use(VueRouter);

const cleanLocalStorage = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
) => {
  if (from.name?.indexOf("api-setup") === -1) {
    persistanceService.clearApiPersistance();
  }

  next();
};

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      localizedName: "Home",
      authMode: RouteAuthRequirementEnum.all,
    },
  },
  {
    path: "/account/change-email",
    name: "account-change-email",
    component: ChangeEmailForm,
    meta: {
      localizedName: "Change Email",
    },
  },
  {
    path: "/account/verify-email",
    name: "account-verify-email",
    component: VerifyEmailForm,
    meta: {
      localizedName: "Verify Email",
    },
  },
  {
    path: "/account/change-password",
    name: "account-change-password",
    component: ChangePasswordView,
    meta: {
      authMode: RouteAuthRequirementEnum.all,
      localizedName: "Change Password",
      layout: "no-nav-no-footer",
    },
  },
  {
    path: "/account/settings",
    name: "account-settings",
    component: AccountSettings,
    meta: {
      localizedName: "Profile Settings",
    },
  },
  {
    path: "/org/:orgId/apis",
    name: "org-api-dashboard",
    component: OrgApiDashboard,
    meta: {
      localizedName: "API Dashboard",
      layout: "org",
    },
  },
  {
    path: "/org/:orgId/billing",
    name: "org-billing",
    component: OrgBillingDashboard,
    meta: {
      localizedName: "Billing Dashboard",
      layout: "org",
    },
  },
  {
    path: "/org/:orgId/settings",
    name: "org-settings",
    component: OrgSettings,
    meta: {
      localizedName: "Orga Settings",
      layout: "org",
    },
  },
  {
    path: "/org/:orgId/users",
    name: "org-users",
    component: OrgUserManagement,
    meta: {
      localizedName: "User Management",
      layout: "org",
    },
  },
  {
    path: "/org/:orgId/users/:action",
    name: "org-users-manage",
    props: (route: Route) => ({
      email: route.query.email,
      role: route.query.role,
      roleId: route.query.roleId,
    }),
    component: OrgUserInvite,
    meta: {
      localizedName: "Invite User",
      layout: "org",
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      authMode: RouteAuthRequirementEnum.noAuthOnly,
      localizedName: "Login",
      layout: "no-nav-no-footer",
    },
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: ForgotPasswordView,
    meta: {
      authMode: RouteAuthRequirementEnum.noAuthOnly,
      localizedName: "Password Reset",
      layout: "no-nav-no-footer",
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: SignupView,
    meta: {
      authMode: RouteAuthRequirementEnum.noAuthOnly,
      localizedName: "Sign up",
      title: i18n.t("page_title_signup"),
      layout: "no-nav-no-footer",
      metaTags: [
        {
          name: "description",
          content: i18n.t("page_title_description"),
        },
        {
          property: "og:description",
          content: i18n.t("page_title_description"),
        },
      ],
    },
  },
  {
    path: "/signup-code-entry",
    name: "signup-code-entry",
    component: SignupCodeEntryView,
    meta: {
      authMode: RouteAuthRequirementEnum.noAuthOnly,
      localizedName: "Sign up Code Entry",
      layout: "no-nav-no-footer",
    },
  },
  {
    path: "/workspace-setup",
    name: "workspace-setup",
    component: WorkspaceSetupName,
    meta: {
      localizedName: "Workspace Setup",
      layout: "no-nav",
    },
  },
  {
    path: "/project-setup/name",
    name: "project-setup-name",
    component: ProjectSetupName,
    beforeEnter: cleanLocalStorage,
    meta: {
      localizedName: "API Init Setup",
      layout: "no-nav",
    },
  },
  {
    path: "/project-publish/:apiId/auth",
    name: "project-publish-auth",
    component: ProjectPublishAuth,
    beforeEnter: cleanLocalStorage,
    meta: {
      localizedName: "Project Publish Auth",
      layout: "no-nav",
    },
  },
  {
    path: "/project-publish/:apiId/region",
    name: "project-publish-region",
    component: ProjectPublishRegion,
    meta: {
      localizedName: "Project Publish Region",
      layout: "no-nav",
    },
  },
  {
    path: "/project-publish/:apiId/bundles",
    name: "project-publish-bundles",
    component: ProjectBundlesOverview,
    props: true,
    meta: {
      localizedName: "Bundles",
      layout: "no-nav",
    },
  },
  {
    path: "/api-setup/:apiId/overview",
    name: "project-setup-overview",
    component: ProjectSetupOverview,
    props: true,
    meta: {
      localizedName: "API Setup Overview",
      layout: "no-nav",
    },
  },
  {
    path: "/api-setup/:apiId/billing",
    name: "project-setup-billing",
    component: ApiSetupBilling,
    meta: {
      localizedName: "API Setup Billing",
      layout: "no-nav",
    },
  },
  {
    path: "/api-setup/:apiId/publishing",
    name: "project-setup-publishing",
    component: ApiSetupPublishing,
    props: true,
    meta: {
      localizedName: "API Setup Publishing",
    },
  },
  {
    path: "/api/:apiId/overview",
    name: "project-overview",
  },
  {
    path: "/api/:apiId/apps",
    name: "project-apps",
    component: ApiApps,
    props: true,
    meta: {
      layout: "sidebar",
      localizedName: i18n.t("nav_sidebar_item_apps"),
    },
  },
  {
    path: "/api/:apiId/apps/:appId/editor",
    name: "project-app-editor",
    component: ProjectAppEditor,
    props: true,
    meta: {
      layout: "app",
    },
  },
  {
    path: "/api/:apiId/flows",
    name: "project-flows",
    component: ProjectFlows,
    props: true,
    meta: {
      layout: "sidebar",
      localizedName: i18n.t("nav_sidebar_item_flows"),
    },
  },
  {
    path: "/api/:apiId/flows/:flowId/editor",
    name: "project-flow-editor",
    component: ProjectFlowEditor,
    props: true,
    meta: {
      layout: "flow",
    },
  },
  {
    path: "/api/:apiId/settings",
    name: "project-settings",
    component: ProjectSettings,
    props: true,
    meta: {
      layout: "sidebar",
      localizedName: i18n.t("global_settings"),
    },
  },
  {
    path: "/api/:apiId/logs",
    name: "project-logs",
    component: ProjectLogs,
    props: true,
    meta: {
      layout: "sidebar",
      localizedName: i18n.t("nav_sidebar_item_logs"),
    },
  },
  {
    path: "/api/:apiId/assets",
    name: "project-assets",
    component: ProjectAssetsExplorer,
    props: true,
    meta: {
      layout: "sidebar",
      localizedName: i18n.t("nav_sidebar_item_assets"),
    },
  },
  {
    path: "/api/:apiId/cloud-functions",
    name: "project-cloud-functions",
    component: ProjectCloudFunctionsExplorer,
    props: true,
    meta: {
      layout: "sidebar",
      localizedName: i18n.t("nav_sidebar_item_cloud_functions"),
    },
  },
  {
    path: "/api/:apiId/data",
    name: "project-data",
    component: ProjectDataExplorer,
    props: true,
    meta: {
      layout: "sidebar",
      localizedName: i18n.t("nav_sidebar_item_data"),
    },
  },
  {
    path: "/api/:apiId/types",
    name: "project-types",
    component: ApiDetailsDataTypes,
    props: true,
    meta: {
      layout: "sidebar",
      localizedName: i18n.t("nav_sidebar_item_models"),
    },
  },
  {
    path: "/api/:apiId/queries",
    name: "project-queries",
    component: ApiDetailsQueryExplorer,
    props: true,
    meta: {
      layout: "sidebar",
      localizedName: i18n.t("nav_sidebar_item_queries"),
    },
  },
  {
    path: "/api/:apiId/republish/:versionId",
    name: "project-republish-view",
    component: ProjectRepublish,
    props: true,
    meta: {
      localizedName: "API Republish View",
    },
  },
];

export const createRouter = () =>
  new VueRouter({
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
    mode: "history",
    base: process.env.BASE_URL,
    routes,
  });

const router = createRouter();

export const secureRouter = (
  userDataLoader: UserDataLoader,
  userRedirector: UserRedirector
) => {
  router.beforeEach(async (to, from, next) => {
    const state = await userDataLoader.loading;
    const destination = userRedirector.getRedirectBasedOnState(state, to);

    if (destination && destination.name !== to.name) {
      next(destination);
    } else {
      next();
    }
  });

  router.afterEach(async () => {
    const state = await userDataLoader.loading;
    if (state === UserStateEnum.Authenticated) {
      persistanceService.removeActiveRouteKey();
    }
  });
};

export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher; // reset router
}

export default router;
