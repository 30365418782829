import { Vue, Component, Watch } from "vue-property-decorator";
import ApolloClient, { NextLink, Operation, fromPromise } from "apollo-boost";
import { updateApiMutation } from "@/api/api";
import {
  AuthMethodTypeEnum,
  IGraphQlError,
  PublishingStateEnum,
} from "@/api/common-types";
import { authHeadersForAuthMode, IApiState, getApiDetails } from "@/models";
import { ApiSchemaHandler } from "@/lib/apischema-handler";
import { NavModule } from "@/store/modules/nav";
import { GraphapiModule } from "@/store/modules/graphapi";

import { OrgModule, UserModule } from "@/store";
import { getOrg, IOrgState } from "@/models/org";
import ApiManagementNav from "@/components/api-management/ApiManagementNav.component";
import styles from "./ApiManagement.layout.module.scss";
import { Auth } from "@graphapi-io/customer-components";
import DefaultFooter from "@/components/footer/DefaultFooter.component";

@Component
export default class ApiManagement extends Vue {
  isVisible = false;
  isRightSidebarVisible = false;
  apiData: IApiState = {} as IApiState;
  apiClient: ApolloClient<unknown> | null = null;
  apiSchemaHandler: ApiSchemaHandler | null = null;
  authFailed: boolean = false;

  get apiId(): string {
    return this.$route.params.apiId;
  }

  get apiState(): PublishingStateEnum {
    return GraphapiModule.states[this.apiId] as PublishingStateEnum;
  }

  get auth() {
    if (
      !this.apiData.authProviderClientId ||
      !this.apiData.authProviderPoolId
    ) {
      return null;
    }
    return new Auth({
      UserPoolId: this.apiData.authProviderPoolId,
      ClientId: this.apiData.authProviderClientId,
    });
  }

  get publishingFailed(): boolean {
    return this.apiState === "PUBLISHING_FAILED";
  }

  get apiReloadRequired() {
    return GraphapiModule.reloadRequired[this.apiId];
  }

  get isAuthenticated() {
    return GraphapiModule.accessTokens[this.apiId];
  }

  get isCognitoApi() {
    return (
      this.apiData.defaultAuthMode === AuthMethodTypeEnum.AWS_COGNITO_USER_POOLS
    );
  }

  get orgId() {
    return OrgModule.id;
  }

  get showAuthButton() {
    return this.apiData.defaultAuthMode !== AuthMethodTypeEnum.AWS_API_KEY;
  }

  async getApiData(fetchPolicy: any = "cache-first") {
    try {
      const apiData = await getApiDetails({ id: this.apiId }, fetchPolicy);

      await this.switchOrgOnApiMismatch(apiData);
      this.apiData = apiData;
      NavModule.setIsCognitoApi(this.isCognitoApi);
      NavModule.setHasApiGateway(!!this.apiData.apiGatewayUrl);
      if (this.apiData.name) {
        NavModule.setCurrentApiName(this.apiData.name);
      }

      if (this.apiData.id) {
        NavModule.setCurrentApiId(this.apiData.id);
        GraphapiModule.initializeStateEntry({
          apiId: this.apiData.id,
          state: this.apiData.state,
        });
      }

      if (this.apiData.creationType) {
        NavModule.setCurrentApiCreationType(this.apiData.creationType);
      }

      if (this.apiData.graphqlUrl) {
        const apiUrl = this.apiData.apiGatewayUrl
          ? `${this.apiData.apiGatewayUrl}graphql`
          : this.apiData.graphqlUrl;
        const hasApiGatewayUrl = !!this.apiData.apiGatewayUrl;

        this.apiClient = new ApolloClient({
          uri: apiUrl,
          request: (operation) => {
            operation.setContext({
              headers: authHeadersForAuthMode(this.apiData),
            });
          },
          onError: ({ graphQLErrors, networkError, operation, forward }) => {
            if (networkError) {
              if (
                hasApiGatewayUrl &&
                (networkError as any).statusCode === 401
              ) {
                return this.handleUnauthorizedError(
                  operation,
                  forward,
                  async () => {
                    await this.$authManager.refreshToken();
                    return UserModule.accessToken;
                  },
                  () => {
                    this.$bvModal.show("auth-modal");
                  }
                );
              }
              console.log("[Network Error]:", networkError);
            }

            const typedGraphqlErrors = graphQLErrors as unknown as
              | IGraphQlError[]
              | undefined;
            if (typedGraphqlErrors) {
              for (const err of typedGraphqlErrors) {
                switch (err.errorType) {
                  case "Unauthorized":
                  case "UnauthorizedException": {
                    if (!this.isCognitoApi) {
                      this.$bvModal.show("auth-modal");
                      this.authFailed = true;
                      return;
                    }

                    return this.handleUnauthorizedError(
                      operation,
                      forward,
                      this.refreshToken.bind(this),
                      () => {
                        this.$bvModal.show("auth-modal");
                      }
                    );
                  }

                  case "DynamoDB:ProvisionedThroughputExceededException":
                    // think about retry policy
                    break;
                }
              }
            }
          },
        });
        this.apiSchemaHandler = new ApiSchemaHandler(this.apiClient);
        await this.initApiSchemaHandler(this.apiSchemaHandler);
      }
    } catch (err) {
      console.log(err);
    }
  }

  private handleUnauthorizedError(
    operation: Operation,
    forward: NextLink,
    refreshTokenFn: () => Promise<string | null>,
    onFailedRefresh?: () => void
  ) {
    const oldHeaders = operation.getContext().headers;

    return fromPromise(refreshTokenFn())
      .filter((x) => {
        if (x !== null) return true;

        onFailedRefresh && onFailedRefresh();
        this.authFailed = true;
        return false;
      })
      .flatMap((newToken) => {
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: newToken,
          },
        });
        return forward(operation);
      });
  }

  private async refreshToken() {
    if (!this.auth) return null;
    this.authFailed = false;
    try {
      const { session } = await this.auth.refreshToken();
      const newToken = session.getAccessToken().getJwtToken();

      GraphapiModule.updateToken({ apiId: this.apiId, newToken });

      return newToken;
    } catch (err) {
      return null;
    }
  }

  async switchOrgOnApiMismatch(apiData: IApiState) {
    if (this.orgId !== apiData.orgId && apiData.orgId) {
      const orgData: IOrgState = await getOrg(apiData.orgId);
      OrgModule.handleQueryResponse(orgData);
    }
  }

  @Watch("apiState")
  onStateChange() {
    this.apiData.state = this.apiState;
    if (this.apiState === PublishingStateEnum.PUBLISHED) {
      this.getApiData("network-only");
    }
  }

  @Watch("apiReloadRequired", { immediate: true }) onApiReloadRequired() {
    this.getApiData(this.apiReloadRequired ? "network-only" : "cache-first");
    GraphapiModule.handleApiReloaded(this.apiId);
  }

  @Watch("auth") async onAuthChange() {
    try {
      await this.auth?.initialize();
    } catch (err) {
      // Ignore
    }
  }

  mounted() {
    this.isVisible = !this.isVisible;
  }

  async onAuthenticated() {
    this.authFailed = false;
    if (this.apiSchemaHandler) {
      try {
        await this.getApiData();
      } catch (err) {
        console.log(err);
      }
    }
  }

  async onAuthToggle() {
    try {
      if (this.isAuthenticated) {
        GraphapiModule.handleSignOutForApiWithId(this.apiId);
        this.auth?.signOut();
      } else {
        this.$bvModal.show("auth-modal");
      }
    } catch (err) {
      console.log(err);
    }
  }

  handleOpenRightSidebar() {
    // @ts-ignore
    this.$root.toggleBodyScrollbar(true, true);
    this.isRightSidebarVisible = true;
  }

  handleCloseRightSidebar() {
    // @ts-ignore
    this.$root.toggleBodyScrollbar(false, true);
    this.isRightSidebarVisible = false;
  }

  private async initApiSchemaHandler(apiSchemaHandler: ApiSchemaHandler) {
    try {
      await apiSchemaHandler.init();
      if (this.apiData.state === PublishingStateEnum.IMPORTED) {
        this.apiData.schema = apiSchemaHandler.extractApiDeclaration(
          this.apiData
        );
        await updateApiMutation({
          id: this.apiData.id,
          schema: this.apiData.schema,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <div class="shared-layout">
        <div class={styles.apiManagementContainer}>
          <ApiManagementNav
            apiName={this.apiData.name ?? undefined}
            apiId={this.apiData.id}
            apiState={this.apiData.state}
            apiRegion={this.apiData.region ?? undefined}
            apiDefaultAuthMode={this.apiData.defaultAuthMode ?? undefined}
            onAuthToggle={this.onAuthToggle}
          ></ApiManagementNav>
          <div
            class={{
              [styles.fullCanvas]: true,
              [styles.withRightSidebar]: this.isRightSidebarVisible,
            }}
          >
            <div class={styles.apiManagementContent}>
              <div class={styles.scrollWrapper}>
                <router-view
                  value={this.apiData}
                  auth={this.auth}
                  apiClient={this.apiClient}
                  apiSchemaHandler={this.apiSchemaHandler}
                  key={`api-details-${this.apiId}`}
                  authFailed={this.authFailed}
                  onOpenRightSidebar={this.handleOpenRightSidebar}
                  onCloseRightSidebar={this.handleCloseRightSidebar}
                />
              </div>
            </div>
            <div class={styles.sidebarRight}>
              <portal-target
                name="right-sidebar"
                class={styles.sidebarRightPortal}
              />
            </div>
          </div>
        </div>
        <DefaultFooter />
      </div>
    );
  }
}
