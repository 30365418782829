import { BaseFieldTypeEnum, IFieldType } from "@/models";
import { SortOrderType } from "@/generated/types";
import { Element } from "vue-tsx-support/types/base";

export type ICellDataContent =
  | string
  | number
  | string[]
  | number[]
  | null
  | undefined;

export interface ICellIndex {
  column: number;
  row: number;
}

export type ITableRange = {
  minRowIndex: number;
  maxRowIndex: number;
  minColIndex: number;
  maxColIndex: number;
};

export interface IItem {
  [key: string | number | symbol]: string | number | null | undefined;
}

export interface ICellClickPayload {
  item?: IItem;
  index: number;
  relativeIndex: number;
  field: string;
  isCmdPressed: boolean;
  isShiftPressed: boolean;
}

export interface IHeaderCellClickPayload {
  field: string;
  itemsOnAPageCount: number;
  isCmdPressed: boolean;
  isShiftPressed: boolean;
}

export enum ReservedFieldsEnum {
  selected = "selected",
  edit = "edit",
  addColumn = "addColumn",
  placeholder = "placeholder",
}

export interface ITableField {
  key: string;
  label: string;
  type?: IFieldType;
  iconSrc?: string;
  inverseIconSrc?: string;
  formatter?: (content: ICellDataContent, item?: IItem) => string | Element;
  readOnly?: boolean;
  inactive?: boolean;
  configurable?: boolean;
  options?: { text: string; value: string }[];
  sortOrder?: SortOrderType;
}

export interface IHeaderFieldConfig {
  name: string;
  type: BaseFieldTypeEnum;
  isReadonly: boolean;
  isConfigurable: boolean;
}

export interface IFieldConfig {
  field: ITableField;
  stickyColumn?: boolean;
  index: number;
}

export interface IPaginationState {
  perPage: number;
  currentPage: number;
  pageSizes: number[];
}

export interface ICellChangePayload {
  item: IItem;
  field: string;
  value: ICellDataContent;
  index: number;
}
