import { Component, Emit, Prop } from "vue-property-decorator";
import Navbar from "@/components/navbar/Navbar.component.vue";
import ApiManagementBreadcrumbs from "./ApiManagementBreadcrumbs.component";
import * as tsx from "vue-tsx-support";
import Media from "@/mixins/Media.mixin";
import { INavSection, NavModule } from "@/store";
import styles from "./ApiManagementNav.component.module.scss";
import Dropdown from "../shared/Dropdown.component";
import {
  Button,
  ButtonShapeEnum,
  ButtonSizeEnum,
  ButtonThemeEnum,
} from "../shared/Button.component";
import { PublishingStateEnum } from "@/api/common-types";
import { mixins } from "vue-class-component";
import ApiPublishedTypes from "./ApiPublishedTypes.mixin";
import { PublishingState } from "@/generated/types";

@Component
export default class ApiManagementNav extends mixins(Media, ApiPublishedTypes) {
  _tsx: tsx.DeclareProps<{
    apiName?: ApiManagementNav["apiName"];
    apiId: ApiManagementNav["apiId"];
    apiState: ApiManagementNav["apiState"];
    apiRegion?: ApiManagementNav["apiRegion"];
    apiDefaultAuthMode?: ApiManagementNav["apiDefaultAuthMode"];
  }> &
    tsx.DeclareOnEvents<{
      onAuthToggle: void;
    }>;

  @Prop({ default: "" }) apiName: string;
  @Prop({ required: true }) apiState: string;
  @Prop({ default: "" }) apiRegion: string;
  @Prop({ default: "" }) apiDefaultAuthMode: string;

  @Emit("authToggle") onAuthToggle() {}

  private get navItems(): INavSection[] {
    return NavModule.apiDetailsNavItems;
  }

  private get flattenedNavItems() {
    return this.navItems.flatMap((section) => section.items);
  }

  private get showPublishButton() {
    return (
      (this.apiData?.state !== PublishingState.IMPORTED &&
        (!this.apiData?.graphqlUrl || this.hasUnpublishedChanges)) ||
      this.isPublishing
    );
  }

  render() {
    return (
      <Navbar
        showSecondaryNav={true}
        scopedSlots={{
          secondaryNavbar: (props) =>
            this.isMobile ? (
              <ApiManagementBreadcrumbs
                onLinkClick={props.onLinkClick}
                onToggleMobileDropdown={props.toggleMobileDropdown}
                apiName={this.apiName}
              />
            ) : (
              <ul class={styles.linksList}>
                {this.flattenedNavItems.map((item) => (
                  <router-link
                    key={item.link.name}
                    custom
                    to={{
                      name: item.link.name,
                      params: { apiId: this.apiId },
                    }}
                    scopedSlots={{
                      default: ({
                        href,
                        navigate,
                        isActive,
                      }: {
                        href: string;
                        navigate: () => void;
                        isActive: boolean;
                      }) => (
                        <li
                          class={{
                            [styles.listItem]: true,
                            [styles.active]: isActive,
                          }}
                        >
                          <a href={href} onClick={navigate} class={styles.link}>
                            {item.icon && (
                              <img
                                src={item.icon}
                                class={styles.icon}
                                title={item.text}
                              />
                            )}
                            <span class={styles.linkText}>{item.text}</span>
                          </a>
                        </li>
                      ),
                    }}
                  />
                ))}
              </ul>
            ),
        }}
      >
        <template slot="navigationLeft">
          <Dropdown
            buttonText={this.apiName}
            toggleClass={styles.projectName}
            dropdownMenuClass={styles.dropdownMenu}
            leftPlateOffset={0}
            topPlateOffset={10}
          >
            <Dropdown.Element>
              <span
                class={{
                  "api-publishing-status": true,
                  init: this.apiState === PublishingStateEnum.INITIALIZED,
                  failed:
                    this.apiState === PublishingStateEnum.PUBLISHING_FAILED,
                  live: this.apiState === PublishingStateEnum.PUBLISHED,
                }}
              >
                {this.apiState && this.$t(`api_status.${this.apiState}`)}
              </span>
            </Dropdown.Element>
            <Dropdown.SectionHeader>
              {this.$t("global_region")}
            </Dropdown.SectionHeader>
            <Dropdown.SectionText>
              {this.apiRegion && this.$t(`aws_region.${this.apiRegion}`)}
            </Dropdown.SectionText>
            <Dropdown.SectionHeader>
              {this.$t("global_auth")}
            </Dropdown.SectionHeader>
            <Dropdown.SectionText>
              {this.apiDefaultAuthMode &&
                this.$t(`auth_mode.${this.apiDefaultAuthMode}`)}
            </Dropdown.SectionText>
          </Dropdown>
        </template>

        <template slot="navigationLeftDesktop">
          {this.showPublishButton && (
            <Button
              class={styles.publishButton}
              theme={ButtonThemeEnum.primary}
              shape={ButtonShapeEnum.edgeToEdge}
              size={ButtonSizeEnum.sm}
              onClick={this.handlePublishClick}
              data-testid="publishApiButton"
            >
              {this.isPublishing
                ? this.$t("url_widget_publish_cta_progress")
                : this.apiData?.graphqlUrl && this.hasUnpublishedChanges
                ? this.$t("form_cta_publish_changes")
                : this.$t("form_cta_publish")}
            </Button>
          )}
        </template>
      </Navbar>
    );
  }
}
