import { updateRoleMutation } from "@/api/role";
import { IOrgState, getOrg, getOrgNames } from "@/models/org";
import { createUser, getUser, IRoleState } from "@/models/user";
import { OrgModule, UserModule } from "@/store";
import { getErrorMessage, isNotNullable } from "..";
import { AuthStateHandler } from "./auth-state-handler";
import { UserStateEnum } from "./types";
import _uniqBy from "lodash/uniqBy";
import { ICredentials } from "@graphapi-io/customer-components";

export class UserDataLoader {
  constructor(authStateHandler: AuthStateHandler) {
    this.authStateHandler = authStateHandler;
  }

  private authStateHandler: AuthStateHandler;

  public loading: Promise<UserStateEnum> = Promise.resolve(
    UserStateEnum.NotAuthenticated
  );

  private async getRoles() {
    const { roles } = await getUser(UserModule.userId, "network-only");
    let activeRole: undefined | IRoleState;

    if (roles.length > 0) {
      const orgIds = _uniqBy(roles, "orgId")
        .map((role: IRoleState) => role.orgId)
        .filter(isNotNullable);
      const orgNames = await getOrgNames(orgIds);
      const normalizedRoles = roles.filter(
        (role) => !!orgNames.find((org) => org.id === role.orgId)
      );

      UserModule.handleRoleConnections(normalizedRoles);
      const activeOrgId = OrgModule.id;
      activeRole =
        normalizedRoles.find((role) => role?.orgId === activeOrgId) ??
        normalizedRoles[0];
    }

    return { activeRole, roles };
  }

  private async getOrg(orgId: string) {
    OrgModule.handleOrgLoadingStarted();
    const orgData: IOrgState = await getOrg(orgId);
    OrgModule.handleQueryResponse(orgData);
  }

  private async handleInitialize() {
    const status = await this.authStateHandler.initialize();

    if (status != UserStateEnum.Authenticated) {
      return status;
    }

    try {
      const { activeRole } = await this.getRoles();

      if (!activeRole) {
        return UserStateEnum.NoRoles;
      }

      if (activeRole.orgId) {
        await this.getOrg(activeRole.orgId);
      }

      return UserStateEnum.Authenticated;
    } catch (err) {
      this.authStateHandler.signOut();
      UserModule.handleAuthError(getErrorMessage(err));
      return UserStateEnum.FailedToGetRoles;
    }
  }

  private async handleRefreshToken() {
    const result = await this.authStateHandler.refreshToken();
    return result;
  }

  private async updateRoleLastSignIn(activeRole: IRoleState) {
    await updateRoleMutation(
      {
        input: {
          id: activeRole.id,
          lastSignInAt: new Date(Date.now()).toISOString(),
        },
      },
      activeRole.orgId ?? undefined
    );
  }

  private async handleOrgSwitch() {
    const { activeRole } = await this.getRoles();
    if (activeRole) {
      this.updateRoleLastSignIn(activeRole);
    }
  }

  private async handleRoles() {
    try {
      const { activeRole } = await this.getRoles();

      if (!activeRole) {
        return UserStateEnum.NoRoles;
      }

      if (activeRole.orgId) {
        await this.getOrg(activeRole.orgId);
      }

      if (activeRole.id?.length > 0) {
        this.updateRoleLastSignIn(activeRole);
      }

      return UserStateEnum.Authenticated;
    } catch (err) {
      UserModule.handleAuthError(getErrorMessage(err));
      return UserStateEnum.FailedToGetRoles;
    }
  }

  private async handleSignIn(credentials: ICredentials) {
    const result = await this.authStateHandler.signIn(credentials);

    if (result !== UserStateEnum.Authenticated) return result;

    return this.handleRoles();
  }

  private async handleConfirmCode(credentials: ICredentials) {
    const result = await this.authStateHandler.signIn(credentials);
    await this.authStateHandler.refreshToken();

    if (result !== UserStateEnum.Authenticated) return result;

    try {
      await createUser({
        email: UserModule.email,
        id: UserModule.userId,
      });
      const result = await this.authStateHandler.refreshToken();

      if (result !== UserStateEnum.Authenticated) return result;

      return this.handleRoles();
    } catch (err) {
      UserModule.handleAuthError(getErrorMessage(err));
      return UserStateEnum.NotAuthenticated;
    }
  }

  public initialize() {
    this.loading = this.handleInitialize();
  }

  public signIn(credentials: ICredentials) {
    this.loading = this.handleSignIn(credentials);
  }

  public confirmCode(credentials: ICredentials) {
    this.loading = this.handleConfirmCode(credentials);
  }

  public refreshToken() {
    this.loading = this.handleRefreshToken();
  }

  public signOut() {
    this.loading = this.authStateHandler.signOut();
  }

  public switchOrg() {
    this.handleOrgSwitch();
  }

  public get auth() {
    return this.authStateHandler.auth;
  }
}

export type IUserDataLoader = Required<UserDataLoader>;
